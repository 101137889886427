import { activeTheme, themeStyle } from "src/themes";
import { lazy, Suspense, useEffect, useState } from "react";
import { useSelector ,useDispatch } from "react-redux";
import { LIKE_PROFILE, SEND_POKE, USER_PROFILE ,PURCHASE_USER_PHOTO ,USER_PURCHASED_MEDIA} from "src/queries";
import { apolloClient } from "src/index";
import { useParams } from "react-router";
import { PageSkeleton } from "src/components/common/Skeletons";
import { toast } from "react-toastify";
import { setUserFlirts } from "src/redux/index";
import UserCloudGallery from 'src/components/Main/UserCloudGallery'
import { useQuery } from "@apollo/client";

const Template = lazy(() =>
  import(`src/templates/${activeTheme}/views/Profile.jsx`)
);
function Profile() {
  const params = useParams();
  const dispatch = useDispatch()
  const [userProfileData, setUserProfileData] = useState(null);
  const [media,setMedia] = useState([])

  const {
    data: purchasedMedia,
    loading: purchasedMediaLoading,
    error: purchasedMediaError,
    refetch:refetchPurchasedMedia
  } = useQuery(USER_PURCHASED_MEDIA);

  console.log(purchasedMedia,"purchaseMediapurchaseMedia")

  

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUserProfile();
  }, []);


  const purchageImage = async (id) => {
    try {
      const {data,errors} = await apolloClient.mutate({
        mutation: PURCHASE_USER_PHOTO,
        variables: {
          media: id,
        },
      });
      dispatch(setUserFlirts(data.purchaseUserPhoto.credit.balance));
      getUserProfile();
      return {
        status:true,
        data:data.purchaseUserPhoto
      }
    } catch (error) {
      return {
        status:false,
        error:error
      }
    }
  }

  const getUserProfile = async () => {
    setLoading(true);
    try {
      const { data, errors } = await apolloClient.query({
        query: USER_PROFILE,
        fetchPolicy: "no-cache",
        variables: {
          username: params.username,
        },
      });
      if (!data.userProfile.user) {
        return alert("user not found");
      }
      setLoading(false);
      // let userPhotos = await setUserPhotos(data.userProfile.user.photos)
      // data.userProfile.user.photos = userPhotos
      console.log(data.userProfile,"data.userProfile")
      let userData = data.userProfile
      let allMedia = []
        if(userData.user.mediaGallery.length){ 
          userData.user.mediaGallery.forEach(gallery => {
              if(gallery) {
                gallery.media.forEach((media)=>{
                  allMedia.push(media)
                })
              }
          });
        }
        console.log(allMedia,"allMedia")
      setUserProfileData(data.userProfile);
      setMedia(allMedia)
    } catch (e) {
      setLoading(false);
      console.log(e);
      // alert("error")
    }
  };

  const sendPoke = async (username) => {
    try {
      await apolloClient.mutate({
        mutation: SEND_POKE,
        variables: {
          input: username,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const likeProfile = async (username) => {
    try {
      await apolloClient.mutate({
        mutation: LIKE_PROFILE,
        variables: {
          user: username,
        },
      });
      toast.success(`You have liked ${username}'s profile!`, {
        autoClose: 5000,
      
        style: {
          backgroundColor: themeStyle.background,
          color: "white",
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
    <Suspense fallback={<PageSkeleton />}>
      <Template
        loading={loading}
        userProfileData={userProfileData}
        isPublic={false}
        sendPoke={sendPoke}
        likeProfile={likeProfile}
        purchageImage={purchageImage}
      />
    </Suspense>
     <UserCloudGallery
       purchasedMedia={purchasedMedia}
       media={media}
       refetchPurchasedMedia={refetchPurchasedMedia}
       userProfileData={userProfileData}
       isPublic={false}
     />
    </>
  );
}

export default Profile;
