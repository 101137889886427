import { Link } from "react-router-dom";
import { communityMeta, getDomainMeta } from "src/themes";
import Footer from "../components/Footer/Footer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function Terms() {
  const { t } = useTranslation();
  const userData = useSelector((state) => state.user);
  const appData = useSelector((state) => state.app);
  const domainMeta = getDomainMeta();

  return (
    <>
      <div class="px-4 pt-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <div class="relative flex items-center justify-between ">
          <a
            href="/"
            aria-label="Company"
            title="Company"
            class="indivne-flex items-center"
          >
            <img
              src={`https://mazutech.online/${appData.communityMeta?.logo}`}
              alt=""
              className=" object-cover w-32"
            />
          </a>
          <ul class="flex items-center lg:flex">
            <div>
              {!userData.token && (
                <Link to="/login">
                  <button
                    class="indivne-flex items-center justify-center h-12 px-6 font-medium tracking-wide bg-red-900 text-white transition duration-200 rounded shadow-md bg-deep-red-accent-400 hover:bg-deep-red-accent-700 focus:shadow-outdivne focus:outdivne-none"
                    aria-label="Sign up"
                  >
                    {t("Login")}
                  </button>
                </Link>
              )}
            </div>
          </ul>
        </div>

        <div
          className="
						py-12
						mx-auto
						sm:max-w-xl
						md:max-w-full
						lg:max-w-screen-xl
						md:px-24
						lg:px-8
						lg:py-20 flex items-center justify-center flex-col"
        >
          <p class="pb-3 text-2xl sm:text-4xl">
            <strong>{t("Terms and Conditions")}</strong>
          </p>

          <div className="flex items-center justify-center flex-col">
            <div className="flex items-center flex-col justify-center">
              <span>{`${domainMeta.header_heading2}`}</span>
              <span>{`${domainMeta.addressline1}`}</span>
              <span>{`${domainMeta.addressline2}`}</span>
            </div>
            <div className="flex items-center flex-col justify-center">
              <button>
                {t("email")}: {`${domainMeta.mail}`}
              </button>
              <button>
                Internet:{" "}
                <span className="text-[#2563EB]">{`${domainMeta.internet}`}</span>{" "}
              </button>
            </div>
            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">
                  {" "}
                  1. {t("Scope of Application")}
                </strong>
              </p>
              <p>
                <strong> 1.1</strong> {""}{" "}
                {t(
                  "The following General Terms and Conditions are an integral part of every contract concluded by means of the"
                )}{" "}
                {""}
                {`${domainMeta.communityName}`}{" "}
                {t("smartphone app or the website")}{" "}
                <a
                  className="text-[#2563EB]"
                  href={`${domainMeta.internet}`}
                  target="_blank"
                  rel="noreferrer"
                >{`${domainMeta.communityName}`}</a>{" "}
                {""}
                {t("(hereinafter referred to as 'Service') between")}{" "}
                {`${domainMeta.addressline1}. ${domainMeta.addressline2}`}. and
                the User.
              </p>

              <p>
                <strong> 1.2</strong> {""}{" "}
                {t(
                  "The Operator provides its services exclusively on the basis of these General Terms and Conditions."
                )}
              </p>
              <p>
                <strong> 1.3</strong> {""}{" "}
                {t(
                  "Persons who have not yet reached the age of 18, or who are in guardianship or a care relationship in whole or in part and thus do not have full legal capacity may not use the Service. The User confirms and accepts these regulations with his/her registration. In case of violation or contravention of the regulations, the respective user account will be deleted without consultation and regardless of any remaining credit balances. The same regulation applies in the case of the use of the Service for commercial purposes. The user account will also be deleted by the Operator in such cases."
                )}
              </p>
            </div>
            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">
                  {" "}
                  2.{t("Conclusion of the Contract")}
                </strong>
              </p>
              <p className="text-center">
                {t(
                  "The contract between the Operator and the User shall be concluded by the creation of a user account by means of the free registration of the User on the website or by means of the respective mobile apps."
                )}
              </p>
            </div>
            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">
                  {" "}
                  <strong> 3.</strong> {t("Subject Matter of the Contract")}
                </strong>
              </p>
              <p className="text-center">
                <strong> 3.1</strong> {""}{" "}
                {t(
                  "The Service provided by the Operator offers the User the opportunity to communicate interactively and engage in online dating with other users. To this end, the Operator offers users access to its database and the user profiles that are stored in it. Users can view these profiles, add their own profile to the database, and contact other users. Contact and communication between users shall be conducted by means of the Service."
                )}
              </p>

              <p className="text-center">
                <strong> 3.2</strong> {""}{" "}
                {t(
                  "The Operator shall only be responsible for the attempt to establish contact with other users and does not guarantee that contact will actually be established."
                )}
              </p>
              <p className="text-center">
                <strong> 3.3</strong> {""}{" "}
                {t(
                  "The use of the basic Service is free of charge; only the individual services which are additionally offered by the Operator are subject to a charge. A detailed description of these services is provided within the Service itself."
                )}
              </p>
              <p className="text-center">
                <strong> 3.4</strong> {""}{" "}
                {t(
                  "Should the User wish to use a chargeable service, he/she can purchase Fdivrts for this purpose from the Operator, which are credited to his/her user account. Payment shall be made by the type and means of payment described in each case."
                )}
              </p>
              <p className="text-center">
                <strong> 3.5</strong> {""}{" "}
                {t(
                  "Should the User wish to use a chargeable service and pay by means of Fdivrts, the Operator will inform the User how many Fdivrts will be charged for the use of the respective service."
                )}
              </p>
              <p className="text-center">
                <strong> 3.6</strong> {""}{" "}
                {t(
                  "In order to guarantee users an optimal service experience and ensure availability of the Service at all times, the Operator employs professional entertainers and profile attendants to look after and entertain users. Such persons are not identified in a special manner by the system. Users can only send messages to and conduct dialogues with such individuals within the Service; they cannot meet them in person."
                )}
              </p>
              <p className="text-center">
                <strong> 3.7</strong> {`${domainMeta.communityName}`}{" "}
                {t(
                  "Grants each user a one-time welcome bonus of 50 Fdivrts. The Operator reserves the right to deactivate the accounts of users that have created multiple user accounts in order to achieve unauthorized multiple use of the welcome bonus."
                )}
              </p>
            </div>

            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">
                  {" "}
                  {t("Contractual Obligations")}
                  4.
                </strong>
              </p>
              <p className="text-center">
                <strong> 4.1</strong> {""}{" "}
                {t(
                  "The Operator shall make the Service available to the User and grant a simple right of use that is unrestricted in terms of location or time. However, this right of use is limited to the use of the content provided for private purposes."
                )}
              </p>
              <p className="text-center">
                <strong> 4.2</strong> {""}{" "}
                {t(
                  "Upon registration, the User shall provide the Operator with the requested information both completely and truthfully. The Operator must be notified immediately of any changes to such information."
                )}
              </p>
              <p className="text-center">
                <strong> 4.3</strong>
                {""}{" "}
                {t(
                  "The content provided by the User for publication through the Service and the manner in which it is presented must comply with the applicable legal regulations, and must not infringe the rights of third parties; in particular, the User may not publish any defamatory, offensive, racist or otherwise illegal content."
                )}
              </p>
              <p className="text-center">
                <strong> 4.4</strong>
                {""}{" "}
                {t(
                  "The User is not permitted to use either the Service or the data of other users for commercial purposes."
                )}
              </p>
              <p className="text-center">
                <strong> 4.5</strong>
                {""}{" "}
                {t(
                  "The User shall treat messages and the personal data of other users that he/she receives in connection with using the Service confidentially, and not make such messages or data accessible to third parties without the consent of the user concerned."
                )}
              </p>
              <p className="text-center">
                <strong> 4.6</strong>
                {""}
                {t(
                  "Should the User violate his/her contractual obligations under § 4 para. 3 to para. 5 of these General Terms and Conditions, the Operator shall be entitled to remove the User's content from the Service, block his/her access, and — in the event of gross violations — be entitled to terminate the contractual relationship extraordinarily with immediate effect. The User shall retain the right to prove that blocking his/her access was unjustified. In the event that the User has chosen to avail of a chargeable service at the time when access is blocked or the contract is terminated, the Operator's right to remuneration shall remain unaffected."
                )}
              </p>
              <p className="text-center">
                <strong> 4.7</strong>
                {""}{" "}
                {t(
                  "The User shall bear responsibility for all the content he/she provides and publishes through his/her account, unless he/she is not accountable for this content."
                )}
              </p>
              <p className="text-center">
                <strong> 4.8</strong>
                {""}{" "}
                {t(
                  "The User shall ensure that he/she has the appropriate rights to all content (e.g. texts, images, etc.) that he/she or the Operator publishes for him/her through the Service."
                )}
              </p>
              <p className="text-center">
                <strong> 4.9</strong>
                {""}{" "}
                {t(
                  "Should third parties assert claims against the Operator in connection with the publication of content by the User through the Service, or in connection with the illegal use of the Service by the User for which the User is accountable, the User undertakes to release the Operator completely and immediately from such claims."
                )}
              </p>
              <p className="text-center">
                <strong> 4.10</strong>
                {""}
                {t(
                  "The User shall have no legal claim to updates or upgrades of the Service. The Operator will nevertheless make every effort to provide regular updates and upgrades."
                )}
              </p>
            </div>

            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong> 5. {t("Further Development of the Service")} </strong>
              </p>
              <p className="text-center">
                <strong> 5.1</strong>
                {""}{" "}
                {t(
                  "The Operator reserves the right to make further developments and improvements to the Service (e.g. by adopting newer or different technologies, systems, procedures or standards) in the interest of technical progress and performance optimization after conclusion of the contract. The Operator shall inform the User in good time in the event of significant changes to the Service. Should the User suffer significant disadvantages as a result of changes to the Service, he/she shall have the right to terminate the contract exceptionally on the date of such changes. The User shall terminate the contract within two weeks of receiving notification of the changes to the Service."
                )}
              </p>
              <p className="text-center">
                <strong> 5.2</strong>
                {""}{" "}
                {t(
                  "When providing new versions of the Service, the Operator shall also grant the User the rights vested in § 4 of these General Terms and Conditions for the respective new version."
                )}
              </p>
              <p className="text-center">
                <strong> 5.3</strong>
                {""}{" "}
                {t(
                  "MAZU™ powered by gf 168 214 Baker Street Enfield 47910 London United Kingdom EN1 3JT. 2021"
                )}
                <br />
                <strong>{t("Author and Concept:")}</strong> {""} Eric Rönnau,
                {t("Development")}: Sumit, Nilshan
                <br />
              </p>
            </div>

            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong> 6. {t("disability of the Operator")} </strong>
              </p>
              <p className="text-center">
                <strong> 6.1</strong>
                {""}{" "}
                {t(
                  "The Operator assumes no liability for assuring that contact between users actually occurs. Liability is also excluded in the event that no contact occurs during the term of the contract; this also applies to the use of services for which a fee is charged."
                )}
              </p>
              <p className="text-center">
                <strong> 6.2</strong>
                {""}{" "}
                {t(
                  "In principle, the statutory right to assert claims for defective service applies, unless otherwise provided for in § 6 para. 3 to para. 5 of these General Terms and Conditions."
                )}
              </p>
              <p className="text-center">
                <strong> 6.3</strong>
                {""}{" "}
                {t(
                  "The Operator is only liable for damages other than those resulting from injury to life, body, or health if the damages are due to deliberate or grossly negligent activities or the culpable breach of a fundamental contractual obligation by the Operator, its employees, or its vicarious agents. This also applies to damages resulting from the violation of obligations during contractual negotiations and from the performance of unauthorized activities. Any further liability for damages is excluded."
                )}
              </p>
              <p className="text-center">
                <strong> 6.4</strong>
                {""}{" "}
                {t(
                  "Except in the case of intentional or grossly negligent conduct, the violation of a cardinal obligation, or injury to life, body and health by the Operator, its employees or its vicarious agents, liability is limited to the damages typically foreseeable at the time of conclusion of the contract, and otherwise to the average amount of damages typical to the contract. This also applies to indirect damages, in particular loss of profit."
                )}
              </p>
              <p className="text-center">
                <strong>
                  6.5{" "}
                  {t(
                    "The provisions of the Product divabidivty Act remain unaffected."
                  )}
                </strong>
              </p>
            </div>

            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">
                  <strong> 7.{t("Contract Termination")}</strong>
                </strong>
              </p>
              <p className="text-center">
                <strong> 7.1</strong>
                {""}{" "}
                {t(
                  "The contract between the Operator and the User shall be concluded for an indefinite period."
                )}
              </p>
              <p className="text-center">
                <strong> 7.2</strong>
                {""}{" "}
                {t(
                  "The contract may be terminated by either party at any time and without notice. In the event that the User has chosen to avail of a chargeable service at the time of the notice of termination, the termination shall become effective at the time when the chargeable service expires."
                )}
              </p>
              <p className="text-center">
                <strong> 7.3</strong> {""}{" "}
                {t(
                  "This shall not affect the right to extraordinary termination for good cause."
                )}
              </p>
            </div>

            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">
                  8. {t("Refund / Revocation")}
                </strong>
              </p>
              <p className="text-center">
                {t(
                  "The user acknowledges that the operator is under no obligation to reimburse any virtual currency that has already been used. In this case, revocation is also excluded. Furthermore, there is no entitlement to money or other compensation for unused remaining credit. This applies in particular in the event of a violation of §1 point 3 or §3 point 7 in these terms and conditions, or if the account is deleted/closed by the user himself. However, the operator will examine every application on a case-by-case basis and has the right to make refunds or partial refunds. These reimbursements are only made out of goodwill and without recognition of a legal obligation."
                )}
              </p>
            </div>

            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">
                  {" "}
                  9. {t("Bonus-Fdivrt Game Regulations")}
                </strong>
              </p>
              <p className="text-center">
                {t(
                  "By using the Service, the User agrees to abide by the so-called “house rules” of"
                )}{" "}
                {`${domainMeta.communityName}`},{" "}
                {t(
                  "which dictate proper and acceptable behavior on and off the Service."
                )}
              </p>
            </div>

            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">
                  {" "}
                  {t("The User agrees to refrain from:")}
                </strong>
              </p>
              <p className="text-center">
                {t(
                  "using the Service for any purpose that is illegal or prohibited by this Agreement or any appdivcable law."
                )}
              </p>
              <p className="text-center">
                {t("Using the Service for any harmful or nefarious purpose.")}
              </p>
              <p className="text-center">
                {t("using the Service to damage")} {`${domainMeta.className}`}{" "}
                {t("or other services provided by the Operator.")}
              </p>
              <p className="text-center">
                {t(
                  "Spamming, soliciting money from, or defrauding any other Users."
                )}
              </p>
              <p className="text-center">
                {t(
                  "Impersonating any person or entity or post any images of another person without his or her explicit permission and consent."
                )}
              </p>
              <p className="text-center">
                {t(
                  "Bullying, 'stalking', intimidate, assault, harass, mistreat, or defame any person."
                )}
              </p>
              <p className="text-center">
                {t(
                  "Posting any Content that is hate speech, threatening, sexually explicit, or pornographic; incites violence, or contains nudity or graphic or gratuitous violence."
                )}
              </p>
              <p className="text-center"></p>
              <p className="text-center">
                {t(
                  "Posting any Content that promotes racism, bigotry, hatred, or physical harm of any kind against any group or individual."
                )}
              </p>
              <p className="text-center">
                {t(
                  "Sodivciting passwords for any purpose, or personal identifying information for commercial or unlawful purposes from other users, or disseminate another person’s personal information without his or her explicit permission and consent."
                )}
              </p>
              <p className="text-center">
                {t(
                  "Using another user’s account, share an account with another user, or maintain more than one account."
                )}
              </p>
              <p className="text-center">
                {t(
                  "Creating another account if an account of the User has already been terminated by the Operator—unless the User has obtained the explicit permission of the Operator."
                )}
              </p>
              <p className="text-center">
                {t(
                  "The Operator reserves the right to investigate and/or terminate the Service for a given User without a refund of any purchases if the User is in violation of this Agreement, has misused the Service, or behaved in a way that the Operator regards as inappropriate or unlawful, or in breach of these 'House Rules'; including actions or communications that occur on or off the Service."
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Terms;
