import { useEffect, useState } from "react";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import { GET_CLOUD_URL, PURCHASE_MEDIA } from "src/queries";
import { apolloClient } from "src/index";
import { useDispatch } from "react-redux";
import { setUserFlirts } from "src/redux/index";
import { IMAGE_BASE_URL, CLOUD_BASE_URL } from "src/utils/constants";
import { themeStyle } from "src/themes";
import { useNavigate } from "react-router";

const unlockMedia = async (id) => {
  try {
    let { data } = await apolloClient.mutate({
      mutation: PURCHASE_MEDIA,
      variables: {
        media: id,
      },
    });
    let credit = data.purchaseMedia;
    return {
      status: true,
      credit: credit,
    };
  } catch (e) {
    return {
      status: false,
      error: e,
    };
  }
};

const setCloudUrl = async (media,isPublic,purchasedMedia=null) => {
  if(isPublic) {
    return {
      media
    }
  }
  if(purchasedMedia) {
    let findMedia = purchasedMedia.getUserPurchasedMedia.find(m => m.id == media.id)
    if(!findMedia){ 
      return {
        media
      }
    }
  }
  try {
    const { data, errors } = await apolloClient.query({
      query: GET_CLOUD_URL,
      variables: {
        filename: media.mediaCloudName,
      },
    });
    return {
      media,
      cloudUrl: data.getCloudUrl,
    };
  } catch (e) {
    console.log(e);
  }
};

function UserCloudGallery(props) {
  const { purchasedMedia, media, refetchPurchasedMedia, userProfileData ,isPublic } =
    props;
  const [activeTab, setActiveTab] = useState("gallery");
  let dispatch = useDispatch();
  let navigate = useNavigate()
  let pictures = media.filter((m) => m.format.includes("image"));
  let videos = media.filter((m) => m.format.includes("video"));

  const purchaseMedia = async (id) => {
    let result = await unlockMedia(id);
    if (result.status) {
      console.log(result.credit);
      refetchPurchasedMedia();
      dispatch(setUserFlirts(result.credit.balance));
    } else {
      console.log(result.error.message)
      if(result.error.message == 'Not Authenticated') {
        return navigate('/')
      }
      alert(result.error);
    }
  };

  return (
    <div className="mx-auto py-2 lg:px-32 lg:pt-12">
      <div className="border-b border-gray-200 dark:border-gray-700">
        <ul className="flex flex-wrap items-center justify-center -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
          <li
            onClick={(e) => {
              e.preventDefault();
              setActiveTab("gallery");
            }}
            className={`me-2 ${activeTab === "gallery" && "bg-[#99CCCC] rounded-t-lg"}`}
          >
            <button
              className={`inline-flex items-center justify-center p-3 rounded-t-lg group ${
                activeTab === "gallery" && "text-white"
              }`}
            >
              <i className="fas fa-image w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300"></i>
              Gallery
            </button>
          </li>
          <li
            onClick={(e) => {
              e.preventDefault();
              setActiveTab("pictures");
            }}
            className={`me-2  ${activeTab === "pictures" && "bg-[#99CCCC] rounded-t-lg"}`}
          >
            <button
              className={`inline-flex items-center justify-center p-3 rounded-t-lg group ${
                activeTab === "pictures" && "text-white"
              }`}
            >
              <i className="fas fa-camera w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300"></i>
              Pictures
            </button>
          </li>
          <li
            onClick={(e) => {
              e.preventDefault();
              setActiveTab("videos");
            }}
            className={`me-2 ${activeTab === "videos" && "bg-[#99CCCC] rounded-t-lg"}`}
          >
            <button
              className={`inline-flex items-center justify-center p-3 rounded-t-lg group ${
                activeTab === "videos" && "text-white"
              }`}
            >
              <i className="fas fa-video w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300"></i>
              Videos
            </button>
          </li>
        </ul>
      </div>
      {activeTab == "gallery" && (
        <GalleryImage userProfileData={userProfileData} />
      )}
      {activeTab == "pictures" && (
        <PicturesTab
          purchasedMedia={purchasedMedia}
          pictures={pictures}
          purchaseMedia={purchaseMedia}
        />
      )}
      {activeTab == "videos" && (
        <VideosTab
          purchasedMedia={purchasedMedia}
          videos={videos}
          purchaseMedia={purchaseMedia}
          isPublic={isPublic}
        />
      )}
    </div>
  );
}

const PicturesTab = ({ purchasedMedia, pictures, purchaseMedia }) => {
  const [media, setMedia] = useState([]);
  const [gotMedia, setgotMedia] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let allPromises = [];
    pictures.forEach(async (picture) => {
      let data = setCloudUrl(picture,false);
      allPromises.push(data);
    });
    Promise.all(allPromises).then((values) => {
      setMedia(values);
      setgotMedia(true);
      setLoading(false);
    });
  }, [pictures]);

  if (loading) {
    return (
      <div className="flex items-center justify-center mt-4">
        <div
          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (pictures.length === 0) {
    return (
      <div className="text-center mt-4 text-gray-500 dark:text-gray-400">
        No pictures available.
      </div>
    );
  }

  return (
    <div className="mt-4">
      {!gotMedia && <p>Loading Please wait</p>}
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        {media.map((m) => {
          // let isPurchased = true
          let isPurchased = purchasedMedia.getUserPurchasedMedia.find(
            (pm) => pm.id == m.media.id
          );

          return isPurchased ? (
            <div key={m.media.id}>
              <img
                className="h-auto max-w-full rounded-lg"
                src={m.cloudUrl}
                alt=""
              />
            </div>
          ) : (
            <div key={m.media.id} className="relative">
              <img
                className="h-auto max-w-full rounded-lg blur"
                src={m.cloudUrl}
                alt=""
              />
              <button
                onClick={() => purchaseMedia(m.media.id)}
                type="button"
                className="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800  absolute top-1 right-1 "
              >
                Purchase at {m.media.cost}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const VideosTab = ({ purchasedMedia, videos, purchaseMedia ,isPublic }) => {
  //https://static1.bigstockphoto.com/3/9/3/large2/393923879.jpg
  const [media, setMedia] = useState([]);

  useEffect(() => {
    let allPromises = [];
    videos.forEach(async (video) => {
      let data = setCloudUrl(video,isPublic,purchasedMedia);
      allPromises.push(data);
    });
    Promise.all(allPromises).then((values) => {
      setMedia(values);
    });
  }, [videos]);

  return (
    <div className="mt-4">
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        {media.map((m) => {
          let isPurchased = purchasedMedia.getUserPurchasedMedia.find(
            (pm) => pm.id == m.media.id
          );

          return isPurchased ? (
            <div key={m.media.id}>
              <video controls>
                <source src={m.cloudUrl} />
                Your browser does not support HTML video.
              </video>
            </div>
          ) : (
            <div key={m.media.id} className="relative">
              <img
                className="h-auto max-w-full rounded-lg "
                src={
                  m.media.thumbnail
                    ? CLOUD_BASE_URL + m.media.thumbnail
                    : "https://static1.bigstockphoto.com/3/9/3/large2/393923879.jpg"
                }
                alt=""
              />
              <button
                onClick={() => purchaseMedia(m.media.id)}
                type="button"
                className="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800  absolute top-1 right-1 "
              >
                Purchase at {m.media.cost}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const GalleryImage = ({ userProfileData }) => {
  console.log(userProfileData, "userProfileData");
  if (!userProfileData) return;
  let gallery = userProfileData.user.photos;
  console.log(gallery);
  return (
    <div className="mt-4">
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        <Gallery>
          {gallery.map(({ id, image }) => (
            <Item
              key={id}
              original={`${IMAGE_BASE_URL}${image}`}
              thumbnail={`${IMAGE_BASE_URL}${image}`}
              width="1024"
              height="768"
            >
              {({ ref, open }) => (
                <img
                  ref={ref}
                  onClick={open}
                  src={`${IMAGE_BASE_URL}${image}`}
                  className="h-auto max-w-full rounded-lg cursor-pointer"
                  alt={`User photo ${id}`}
                />
              )}
            </Item>
          ))}
        </Gallery>
      </div>
    </div>
  );
};
export default UserCloudGallery;
