import QuickChatTab from "src/components/Main/QuickChatTab";
import MainChat from "src/components/Main/MainChat";
import {
  GET_MESSAGES,
  GET_UNREAD_MESSAGES,
  READ_MESSAGES,
  DM_TARGET_INFO,
  BUY_NUDE,
  GET_ALL_GIFTS,
} from "src/queries";
import { apolloClient } from "src/index";
import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUserFlirts } from "src/redux/index";
import { useQuery } from "@apollo/client";

const buyNudeMutation = async (id) => {
  try {
    let { data, errors } = await apolloClient.mutate({
      mutation: BUY_NUDE,
      fetchPolicy: "no-cache",
      variables: {
        id: id,
      },
    });
    return {
      status: true,
      data: data.buyNude,
    };
  } catch (e) {
    return {
      status: false,
      message: e,
    };
  }
};

const readMessages = async (messagesId) => {
  try {
    const { data, errors } = await apolloClient.mutate({
      mutation: READ_MESSAGES,
      variables: {
        messages: JSON.stringify(messagesId),
      },
    });
  } catch (e) {
    console.log(e);
  }
};

const conversationExists = async (username) => {
  try {
    const { data, errors } = await apolloClient.query({
      query: DM_TARGET_INFO,
      fetchPolicy: "no-cache",
      variables: {
        dtarget: username,
      },
    });
    return {
      status: true,
      data: data,
    };
  } catch (e) {
    return {
      status: false,
      message: e,
    };
  }
};
function ChatModule(props) {
  const { type } = props;
  const [loading, setLoading] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [showGifts, setShowGifts] = useState(false)

  const userData = useSelector((state) => state.user);
  const chat = useSelector((state) => state.chat);
  const dispatch = useDispatch();

  const {
    loading: giftIsLoading,
    error,
    data: giftData,
  } = useQuery(GET_ALL_GIFTS);

  const buyNude = async (id) => {
    let result = await buyNudeMutation(id);
    if (result.status) {
      dispatch(setUserFlirts(result.data.balance));
    }
    return result;
  };

  const getUnreadMessages = useCallback(async () => {
    try {
      const { data, errors } = await apolloClient.query({
        query: GET_UNREAD_MESSAGES,
        fetchPolicy: "no-cache",
      });
      setUnreadMessages(data.allUnreadMessages);
    } catch (e) {
      console.log(e);
    }
  });
  const getConversationMessages = useCallback(async (id) => {
    setLoading(true);
    try {
      const { data, errors } = await apolloClient.query({
        query: GET_MESSAGES,
        fetchPolicy: "no-cache",
        variables: {
          conv: id,
        },
      });
      let allMessages = data?.allMessages;
      let username = userData.userData.username;

      let opponenetUnreadMessages = allMessages.messages.filter(
        (m) => m.sender.username != username && m.read == false
      );

      opponenetUnreadMessages = opponenetUnreadMessages.map((m) => m.id);
      setSelectedConversation(() => allMessages);
      if (opponenetUnreadMessages.length) {
        await readMessages(opponenetUnreadMessages);
        let newUnreadMessages = unreadMessages.filter(
          (message) => !opponenetUnreadMessages.includes(message.id)
        );
        setUnreadMessages(() => newUnreadMessages);
      }

      setLoading(() => false);

      return data;
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  });

  return (
    <>
      {type == "quick_chat" && (
        <QuickChatTab
          readMessages={readMessages}
          conversationExists={conversationExists}
          unreadMessages={unreadMessages}
          setUnreadMessages={setUnreadMessages}
          selectedConversation={selectedConversation}
          setSelectedConversation={setSelectedConversation}
          userData={userData}
          chat={chat}
          dispatch={dispatch}
          getUnreadMessages={getUnreadMessages}
          getConversationMessages={getConversationMessages}
          loading={loading}
        />
      )}
      {type == "main_chat" && (
        <MainChat
          readMessages={readMessages}
          conversationExists={conversationExists}
          unreadMessages={unreadMessages}
          setUnreadMessages={setUnreadMessages}
          selectedConversation={selectedConversation}
          setSelectedConversation={setSelectedConversation}
          userData={userData}
          chat={chat}
          dispatch={dispatch}
          getUnreadMessages={getUnreadMessages}
          getConversationMessages={getConversationMessages}
          gettingMessagesLoading={loading}
          buyNude={buyNude}
          // getGiftsHandler={getGiftsHandler}
          giftIsLoading={giftIsLoading}
          giftData={giftData}
          showGifts={showGifts}
          setShowGifts={setShowGifts}
        />
      )}
    </>
  );
}

export default ChatModule;
