import { activeTheme } from "src/themes";
import { lazy, Suspense } from "react";
import { PageSkeleton } from "src/components/common/Skeletons";

const Template = lazy(() =>
  import(`src/templates/${activeTheme}/views/Imprint`)
);

function Imprint() {
  return (
    <Suspense fallback={<PageSkeleton />}>
      <Template />
    </Suspense>
  );
}
export default Imprint;
