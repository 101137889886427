import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./user/userReducer";
import loadingReducer from "./loading/loadingReducer";
import chatReducer from "./chat/chatReducer";
import appReducer from "./app/appReducer";
import messageReducer from "./message/messageReducers";

import { combineReducers, applyMiddleware } from "redux";
// import thunk from 'redux-thunk'
// import logger from 'redux-logger'

const reducers = combineReducers({
  user: userReducer,
  loading: loadingReducer,
  chat: chatReducer,
  app: appReducer,
  message: messageReducer,
});
const persistedReducer = persistReducer(
  {
    key: "root",
    blacklist: ["loading", "chat"],
    storage,
  },
  reducers
);
export default () => {
  let store = createStore(persistedReducer);
  let persistor = persistStore(store);
  return { store, persistor };
};
// export default store
