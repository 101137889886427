import { activeTheme } from "src/themes";
import { lazy, Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GET_FLIRTONS } from "src/queries";
import { apolloClient } from "src/index";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";
import { PackageSkeleton } from "src/components/common/Skeletons";

const Template = lazy(() =>
  import(`src/templates/${activeTheme}/views/Flirtons.jsx`)
);
function Flirtons() {
  const { loading, error, data } = useQuery(GET_FLIRTONS);
  const [getPackage, setGetPackage] = useState({
    id:"",
    packageId: "",
    packageName: "",
    showPurchase: "",
  });
  return (
    <Suspense fallback={<PackageSkeleton />}>
      <Template
        loading={loading}
        error={error}
        data={data}
        getPackage={getPackage}
        setGetPackage={setGetPackage}
      />
    </Suspense>
  );
}
export default Flirtons;
