import React, { Suspense, lazy, useEffect, useState } from "react";
import { apolloClient } from "src/index";
import { DISLIKED, DISLIKES } from "src/queries";
import { activeTheme } from "src/themes";
import { LikedLoading } from "src/components/common/Skeletons";

const Template = lazy(() =>
  import(`src/templates/${activeTheme}/views/DisLiked.jsx`)
);

function DisLiked() {
  const [disLikedProfile, setDisLikedProfile] = useState([]);
  const [loading, setLoading] = useState(false);

  const getDislikedUser = async () => {
    try {
      setLoading(true);
      const { data, error } = await apolloClient.query({
        query: DISLIKED,
        fetchPolicy: "no-cache",
      });
      setLoading(false);
      setDisLikedProfile(data.dislikes);
    } catch (e) {
      setLoading(false);
      alert(e);
      console.log(e);
    }
  };

  useEffect(() => {
    getDislikedUser();
  }, []);

  return (
    <Suspense fallback={<LikedLoading />}>
      <Template disLikedProfile={disLikedProfile} loading={loading} />
    </Suspense>
  );
}

export default DisLiked;
