import { activeTheme } from "src/themes";
import { lazy, Suspense } from "react";
import { PageSkeleton } from "src/components/common/Skeletons";

const Template = lazy(() => import(`src/templates/${activeTheme}/views/Terms`));

function Terms() {
  return (
    <Suspense fallback={<PageSkeleton />}>
      <Template />
    </Suspense>
  );
}
export default Terms;
