/* eslint-disable */ 
import {SET_COMMUNITY_META ,SET_FLIRTON_POPUP} from './appTypes'

const initialState = {
  communityMeta:null,
  showFlirtonPop:false
}

const appReducer = (state = initialState,action) => {
  switch(action.type) { 
    case SET_COMMUNITY_META :
      return {
        ... state,
        communityMeta:action.communityMeta,
      }
    case SET_FLIRTON_POPUP :
      return {
        ... state,
        showFlirtonPop:action.value,
      }
    default : return state
  }
}

export default appReducer