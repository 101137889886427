import { Link } from "react-router-dom";
import { communityMeta, getDomainMeta } from "src/themes";
import global_chat from "../../../assets/img/global_chat.png";
import Footer from "../components/Footer/Footer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function Imprint() {
  const { t } = useTranslation();
  const userData = useSelector((state) => state.user);
  const appData = useSelector((state) => state.app);
  const domainMeta = getDomainMeta();

  return (
    <>
      <div class="px-4 pt-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <div class="relative flex items-center justify-between ">
          <a
            href="/"
            aria-label="Company"
            title="Company"
            class="indivne-flex items-center"
          >
            <img
              src={`https://mazutech.online/${appData.communityMeta?.logo}`}
              alt=""
              className=" object-cover w-32"
            />
          </a>
          <ul class="flex items-center lg:flex">
            <div>
              {!userData.token && (
                <Link to="/login">
                  <button
                    class="indivne-flex items-center justify-center h-12 px-6 font-medium tracking-wide bg-red-900 text-white transition duration-200 rounded shadow-md bg-deep-red-accent-400 hover:bg-deep-red-accent-700 focus:shadow-outdivne focus:outdivne-none"
                    aria-label="Sign up"
                  >
                  {t("Login")}
                  </button>
                </Link>
              )}
            </div>
          </ul>
        </div>

        <div
          className="
           flex items-center justify-center flex-col  py-12"
        >
          <p class="pb-3 text-2xl sm:text-4xl">
            <strong>{t("Imprint")}</strong>
          </p>

          <div className="flex items-center justify-center flex-col gap-4">
            <div className="flex items-center flex-col justify-center">
              <span>{`${domainMeta.header_heading2}`}</span>
              <span>{`${domainMeta.addressline1}`}</span>
              <span>{`${domainMeta.addressline2}`}</span>
            </div>
            <div className="flex items-center flex-col justify-center">
              <button>{t("email")}: {`${domainMeta.mail}`}</button>
              <button>
                Internet:{" "}
                <span className="text-[#2563EB]">{`${domainMeta.internet}`}</span>{" "}
              </button>
            </div>

            <div>
              <span className="text-2xl flex items-center justify-center">
             {t("General Terms and Conditions")}
              </span>
              <button>
                <span> {t("You can find our General Terms and Conditions")}  {""}</span>

                <Link className="text-[#2563EB]" to="/terms">
                  {""}
               {t("here")}
                </Link>
              </button>
            </div>
          </div>

          <div className="flex items-center justify-center flex-col mt-16">
            <div className="flex justify-center items-center flex-col">
              <p class="pb-3 text-2xl sm:text-4xl">
                <strong>{t("Liability")}</strong>
              </p>
              <p className=" text-justify sm:text-center">
                {t("Imprint_liability")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Imprint;
