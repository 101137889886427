import { activeTheme } from "src/themes";
import { lazy, Suspense } from "react";
import { apolloClient } from "src/index";
import { USER_EXISTS, NEW_REGISTER } from "src/queries";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setUserData } from "src/redux/index";
import { PageSkeleton } from "src/components/common/Skeletons";
import { useLocation } from "react-router-dom";
const PrivateRegister = lazy(() =>
  import(`src/templates/${activeTheme}/views/PrivateRegister`)
);
const PublicRegister = lazy(() =>
  import(`src/templates/${activeTheme}/views/Register`)
);
function Register() {
  const dispatch = useDispatch();
  const location = useLocation();

  const registerUser = async (captcha_token, formData) => {
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: NEW_REGISTER,
        variables: {
          username: formData.username,
          email: formData.email,
          password: formData.password,
          captcha: captcha_token,
        },
      });
      let userData = data.newRegister;
      if (userData) {
        dispatch(setUserData(userData.user, userData.token, userData.credit));
      }
      setTimeout(() => {
        window.location.href = "/find-matches";
      }, 1000);
      return {
        success: true,
        message: "success",
      };
    } catch (e) {
      return {
        success: false,
        message: e,
      };
    }
  };

  const userExists = async (data) => {
    let checkUserExists = await checkUserDataValid(data.username, data.email);
    if (!checkUserExists.validated) {
      toast.error(checkUserExists.message, {
        position: "top-center",
        autoClose: 1000,
      });
      return {
        success: false,
        message: checkUserExists.message,
      };
    }
    return {
      success: true,
      message: "Success",
    };
  };

  const checkUserDataValid = async (username, email) => {
    try {
      await apolloClient.query({
        query: USER_EXISTS,
        variables: {
          username: username,
          email: email,
        },
      });
      return {
        validated: true,
        message: "Success",
      };
    } catch (e) {
      return {
        validated: false,
        message: e.message,
      };
    }
  };

  const isPublicPage = location.pathname.startsWith("/p/");
  return (
    <Suspense fallback={<PageSkeleton />}>
      {isPublicPage ? (
        <PrivateRegister userExists={userExists} registerUser={registerUser} />
      ) : (
        <PublicRegister userExists={userExists} registerUser={registerUser} />
      )}
    </Suspense>
  );
}

export default Register;
