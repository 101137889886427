/* eslint-disable */ 
import {SET_CHAT ,SET_START_CHAT_MODAL} from './chatTypes'

const initialState = {
  user:null,
  chatType:null,
  showStartChatModal:false
}

const chatReducer = (state = initialState,action) => {
  switch(action.type) { 
    case SET_CHAT :
      return {
        ... state,
        user:action.user,
        chatType:action.chatType
      }
    case SET_START_CHAT_MODAL :
      return {
        ... state,
        showStartChatModal:action.data
      }
    default : return state
  }
}

export default chatReducer