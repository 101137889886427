import { SET_COMMUNITY_META  ,SET_FLIRTON_POPUP} from "./appTypes";

export const setCommunityMeta = (communityMeta) => {
  return {
    type: SET_COMMUNITY_META,
    communityMeta: communityMeta,
  };
};

export const setFlirtonPopup = (value) => {
  return {
    type: SET_FLIRTON_POPUP,
    value: value,
  };
};


