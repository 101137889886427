import { activeTheme } from "src/themes";
import { lazy, Suspense, useEffect } from "react";
import { PageSkeleton } from "src/components/common/Skeletons";
import { PUBLIC_PROFILE } from "src/queries";
import UserCloudGallery from 'src/components/Main/UserCloudGallery'
import { useQuery } from "@apollo/client";
import { useParams } from "react-router";
import { useState } from "react";


const Template = lazy(() =>
  import(`src/templates/${activeTheme}/views/Profile.jsx`)
);

function UserPublicProfile() {
  const params = useParams();
  const [media,setMedia] = useState([])


  const { loading, data } = useQuery(PUBLIC_PROFILE, {
    variables: {
      username: params.username,
    },
  });


  useEffect(()=>{
    if (data) {
      if (!data.publicProfile.user) {
        return alert("user not found");
      }
      console.log(data.publicProfile,"data.userProfile")
        let userData = data.publicProfile
        let allMedia = []
          if(userData.user.mediaGallery.length){ 
            userData.user.mediaGallery.forEach(gallery => {
                if(gallery) {
                  gallery.media.forEach((media)=>{
                    allMedia.push(media)
                  })
                }
            });
          }
          console.log(allMedia,"allMedia")
        // setUserProfileData(data.userProfile);
        setMedia(allMedia)
    }
  },[data])

  
  return (
    <>
      <Suspense fallback={<PageSkeleton />}>
        <Template
          userProfileData={data?.publicProfile}
          loading={loading}
          isPublic={true}
        />
      </Suspense>
      <UserCloudGallery
       purchasedMedia={{getUserPurchasedMedia:[]}}
       media={media}
       refetchPurchasedMedia={()=>{}}
       userProfileData={data?.publicProfile}
       isPublic={true}
     />
    </>
    
  );
}
export default UserPublicProfile;
