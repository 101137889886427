/* eslint-disable */
import { hourlyPackageIsUsed } from "./userActions";
import {
  SET_USER_DATA,
  UPDATE_SETUP_STEP,
  SET_USER_DISCOUNT,
  SET_USER_DISCOUNT_TIMER,
  LOG_OUT_USER,
  CHNAGE_USERNAME,
  SET_UPDATE_PROFILE_STEP,
  SET_COMMUNITY_DOMAIN,
  SET_USER_FLIRTS,
  SET_PROFILE_PIC,
  SET_HOURLY_PACKAGE,
  SET_HOURLY_PACKAGE_IS_USED,
  SET_LOCATION,
  SET_NEARBY_PLACES,
  SET_USER_PROFILE_PHOTO,
} from "./userTypes";

const initialState = {
  userData: null,
  token: null,
  credit: null,
  community_domain: null,
  userType: null,
  hasUsedHourlyPackage: null,
  hourlyPackageIsUsed: false,
  profileSetupStep: null,
  location: null,
  nearbyPlaces: [],
  userDiscount: null,
  userDiscountTimer: null,
  userProfilePhoto: null,
};

const userReducer = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.userData,
        token: action.token,
        credit: action.credit,
      };
    case SET_PROFILE_PIC:
      let userData = { ...state.userData };
      userData.profilePic = action.url;
      return {
        ...state,
        userData: userData,
      };
    case SET_USER_FLIRTS:
      let credit = { ...state.credit };
      credit.balance = action.balance;
      return {
        ...state,
        credit: credit,
      };
    case LOG_OUT_USER:
      return initialState;
    case CHNAGE_USERNAME:
      let UuserData = state.userData;
      UuserData.username = "ssssssssssss";
      return {
        ...state,
        userData: UuserData,
      };
    case SET_COMMUNITY_DOMAIN:
      return {
        ...state,
        community_domain: action.domain,
      };
    case SET_HOURLY_PACKAGE:
      return {
        ...state,
        hasUsedHourlyPackage: {
          isUsed: action.isUsed,
          createdStamp: action.createdStamp,
        },
      };
    case SET_HOURLY_PACKAGE_IS_USED:
      return {
        ...state,
        hourlyPackageIsUsed: action.hourlyPackageIsUsed,
      };

    case SET_UPDATE_PROFILE_STEP:
      return {
        ...state,
        userData: {
          ...state.userData,
          profileSetupStep: action.profileSetupStep,
        },
      };

    case SET_LOCATION:
      return {
        ...state,
        location: action.location,
      };

    case SET_USER_DISCOUNT:
      const userDiscount = action.userDiscount;

      if (userDiscount) {
        const updatedBalance = state.credit.balance + parseFloat(userDiscount);
        return {
          ...state,
          credit: {
            ...state.credit,
            balance: updatedBalance,
          },
          userDiscount: userDiscount,
        };
      }
      return {
        ...state,
        userDiscount: userDiscount,
      };

    case SET_USER_DISCOUNT_TIMER:
      return {
        ...state,
        userDiscountTimer: action.userDiscountTimer,
      };

    case SET_NEARBY_PLACES:
      return {
        ...state,
        nearbyPlaces: action.nearbyPlaces,
      };
    case SET_USER_PROFILE_PHOTO:
      return {
        ...state,
        userData: {
          ...state.userData,
          profilePic: action.profilePic,
        },
      };
    default:
      return state;
  }
};

export default userReducer;
