import { activeTheme } from "src/themes";
import {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from "react";
import { apolloClient } from "src/index";
import { ALL_CONVERSATIONS } from "src/queries";
import { PageSkeleton } from "src/components/common/Skeletons";
const QucikTemplate = lazy(() =>
  import(
    `src/templates/${activeTheme}/components/Main/Sub/QuickUserConversations.jsx`
  )
);
const Template = lazy(() =>
  import(
    `src/templates/${activeTheme}/components/Main/Sub/UserConversations.jsx`
  )
);

function UserConversations(props) {
  const { type, conversationSelectEvent, unreadMessages, getUnreadMessages } =
    props;
  const [loading, setLoading] = useState(false);
  const [conversations, setConversations] = useState([]);

  useEffect(() => {
    getConversations();
    getUnreadMessages();
  }, []);

  useEffect(() => {}, []);

  const getConversations = useCallback(async () => {
    setLoading(true);
    try {
      const { data, errors } = await apolloClient.query({
        query: ALL_CONVERSATIONS,
        fetchPolicy: "no-cache",
      });
      let allConversations = data?.allConversations;
      setConversations(() => allConversations);
      if (allConversations.length && type == "main_chat") {
        conversationSelectEvent(allConversations[0]);
      }
      setLoading(false);
      return data;
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  });
  return (
    <Suspense fallback={<PageSkeleton />}>
      {type === "quick_chat" ? (
        <QucikTemplate
          conversations={conversations}
          conversationSelectEvent={conversationSelectEvent}
          loading={loading}
          unreadMessages={unreadMessages}
        />
      ) : (
        <Template
          conversations={conversations}
          conversationSelectEvent={conversationSelectEvent}
          loading={loading}
          unreadMessages={unreadMessages}
        />
      )}
    </Suspense>
  );
}

export default UserConversations;
