import axios from 'axios'
import { useEffect } from 'react'
import { useDispatch } from "react-redux";
import { setCommunityMeta } from 'src/redux'
const mazuUrl = 'https://mazutech.online/'

const getDatingCommunityMeta = async () => {
  try{
    let communityData = await axios.post(`${mazuUrl}api/system/community-meta`)
    return {
      status:true,
      data:communityData
    }
  }catch(e){
    return {
      status:false,
      error:e
    }
  }
}

function useDatingCommunityMeta() {

  const dispatch = useDispatch()
  useEffect(()=>{
    setDatingCommunityMeta()
  },[])

  const setDatingCommunityMeta = async () => {
    let {data,status} = await getDatingCommunityMeta()
    if(!status) return alert("error fetching meta")
    dispatch(setCommunityMeta(data.data))
  }
}

export default useDatingCommunityMeta