import IndexWeb from "src/views/IndexWeb";
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import store from 'src/redux/store'
import { Loader } from "src/components/SmallComponents";



function App() {
  return (
    <Provider store={store().store}>
      <PersistGate loading={<Loader/>} persistor={store().persistor}>
        <IndexWeb />
      </PersistGate>
    </Provider> 
  );
}

export default App;
