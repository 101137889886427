import { activeTheme } from "src/themes";
import { lazy, Suspense, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "src/redux/user/userActions";
import { NOTIFICATIONS } from "src/queries";
import { useQuery } from "@apollo/client";
const Template = lazy(() =>
  import(`src/templates/${activeTheme}/components/Layout/AppHeader.jsx`)
);

function AppHeader({ toggleSidebar }) {
  const { loading, data } = useQuery(NOTIFICATIONS);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const appData = useSelector((state) => state.app);

  return (
    <Suspense fallback={<p></p>}>
      <Template
        dispatch={dispatch}
        logoutUser={logoutUser}
        userData={userData}
        appData={appData}
        toggleSidebar={toggleSidebar}
        notifications={data?.notifications}
        loading={loading}
      />
    </Suspense>
  );
}
export default AppHeader;
