export const SET_USER_DATA = "SET_USER_DATA";
export const UPDATE_SETUP_STEP = "UPDATE_SETUP_STEP";
export const LOG_OUT_USER = "LOG_OUT_USER";
export const CHNAGE_USERNAME = "CHNAGE_USERNAME";
export const SET_COMMUNITY_DOMAIN = "SET_COMMUNITY_DOMAIN";
export const SET_USER_FLIRTS = "SET_USER_FLIRTS";
export const SET_PROFILE_PIC = "SET_PROFILE_PIC";
export const SET_HOURLY_PACKAGE = "SET_HOURLY_PACKAGE";
export const SET_HOURLY_PACKAGE_IS_USED = "SET_HOURLY_PACKAGE_IS_USED";
export const SET_UPDATE_PROFILE_STEP = "SET_UPDATE_PROFILE_STEP";
export const SET_LOCATION = "SET_LOCATION";
export const SET_USER_DISCOUNT = "SET_USER_DISCOUNT";
export const SET_USER_DISCOUNT_TIMER = "SET_USER_DISCOUNT_TIMER"
export const SET_NEARBY_PLACES = "SET_NEARBY_PLACES";
export const SET_USER_PROFILE_PHOTO = "SET_USER_PROFILE_PHOTO"

