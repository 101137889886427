import { activeTheme } from "src/themes";
import { lazy, Suspense, useEffect, useState } from "react";
import {
  USER_SETTINGS_DATA,
  UPDATE_BASE_PROFILE,
  UPDATE_PROFILE_DETAILS,
  UPDATE_USER_LOOKS,
  UPDATE_USER_LISFESTYLE,
  UPDATE_PROFILE_PIC,
  UPDATE_SOCIAL_LINK,
  MULTIPLE_UPLOAD,
  CHANGE_PASSWORD,
  DELETE_MY_ACCOUNT
} from "src/queries";
import useProfileTypes from "src/hooks/useProfileTypes";
import { apolloClient } from "src/index";
import { useDispatch } from "react-redux";
import { setProfilePic } from "src/redux/index";
import { PageSkeleton } from "src/components/common/Skeletons";
import {logoutUser} from 'src/redux/user/userActions'

const Template = lazy(() =>
  import(`src/templates/${activeTheme}/views/ProfileSettings.jsx`)
);

//updateProfilePic

const updateUserGallery = async (formData) => {
  try {
    const { data, errors } = await apolloClient.mutate({
      mutation: MULTIPLE_UPLOAD,
      fetchPolicy: "no-cache",
      variables: { files: formData },
    });
    if (data.addProfilePics) {
      return {
        status: true,
        data: data.addProfilePics,
      };
    }
  } catch (e) {
    return {
      status: false,
      error: e,
    };
  }
};

const updateSocialLinks = async (formData) => {
  try {
    const { data, errors } = await apolloClient.mutate({
      mutation: UPDATE_SOCIAL_LINK,
      fetchPolicy: "no-cache",
      variables: {
        input: formData,
      },
    });
    if (data.updateSocialLinks) {
      return {
        status: true,
        data: data.updateSocialLinks,
      };
    }
  } catch (e) {
    return {
      status: false,
      error: e,
    };
  }
};

const updateProfilePic = async (formData) => {
  try {
    const { data, errors } = await apolloClient.mutate({
      mutation: UPDATE_PROFILE_PIC,
      fetchPolicy: "no-cache",
      variables: { file: formData },
    });
    if (data.updateProfilePic) {
      return {
        status: true,
        data: data.updateProfilePic,
      };
    }
  } catch (e) {
    return {
      status: false,
      error: e,
    };
  }
};
const updateLifestyleFavourites = async (formData) => {
  try {
    const { data, errors } = await apolloClient.mutate({
      mutation: UPDATE_USER_LISFESTYLE,
      fetchPolicy: "no-cache",
      variables: {
        input: formData,
      },
    });
    if (data.updateUserLifeStyleDetail) {
      return {
        status: true,
      };
    }
  } catch (e) {
    return {
      status: false,
      error: e,
    };
  }
};

const updateUserLooksPersonality = async (formData) => {
  try {
    const { data, errors } = await apolloClient.mutate({
      mutation: UPDATE_USER_LOOKS,
      fetchPolicy: "no-cache",
      variables: {
        input: formData,
      },
    });
    if (data.updateUserLooksDetail) {
      return {
        status: true,
      };
    }
  } catch (e) {
    return {
      status: false,
      error: e,
    };
  }
};

const updateBaseProfile = async (formData) => {
  try {
    const { data, errors } = await apolloClient.mutate({
      mutation: UPDATE_BASE_PROFILE,
      fetchPolicy: "no-cache",
      variables: {
        input: {
          email: formData.email,
          firstName: formData.firstName,
          lastName: formData.lastName,
          phone: formData.phonenumber,
        },
      },
    });
    if (data.updateBaseProfile) {
      return {
        status: true,
      };
    }
  } catch (e) {
    return {
      status: false,
      error: e,
    };
  }
};

const updateProfileDetails = async (formData) => {
  try {
    const { data, errors } = await apolloClient.mutate({
      mutation: UPDATE_PROFILE_DETAILS,
      fetchPolicy: "no-cache",
      variables: {
        input: {
          bio: formData.bio,
          educationLevel: formData.educationLevel,
          location: formData.location,
          relationship: formData.relationship,
          workStatus: formData.workStatus,
        },
      },
    });
    if (data.updateUserProfileDetails) {
      return {
        status: true,
      };
    }
  } catch (e) {
    return {
      status: false,
      error: e,
    };
  }
};

const updatePasswordSettings = async (formData) => {
  try {
    const { data, errors } = await apolloClient.mutate({
      mutation: CHANGE_PASSWORD,
      fetchPolicy: "no-cache",
      variables: {
        input: {
          password: formData.password,
          passwordConfirm: formData.passwordConfirm,
        },
      },
    });
    if (data.changeUserPassword) {
      return {
        status: true,
      };
    }
  } catch (e) {
    console.log(e);
    return {
      status: false,
      error: e,
    };
  }
};



function ProfileSettings() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [saving, setsaving] = useState(false);
  const [userSettingsData, setUserSettingsData] = useState(null);
  const [
    profileSetupTypes,
    looksPersonalityTypes,
    lifestyleTypes,
    setupLoading,
  ] = useProfileTypes();

  const getUserSettingsData = async () => {
    try {
      setLoading(true);
      const { data, errors } = await apolloClient.query({
        query: USER_SETTINGS_DATA,
        fetchPolicy: "no-cache",
      });
      setLoading(false);
      setUserSettingsData(data.userSettingsData);
    } catch (e) {
      setLoading(false);
      alert(e);
    }
  };

  const deleteMyAccount = async () => {
    try {
      setsaving(true);
      const { data, errors } = await apolloClient.mutate({
        mutation: DELETE_MY_ACCOUNT,
      });
      setsaving(false);
      dispatch(logoutUser())
    } catch (e) {
      return {
        status: false,
        error: e,
      };
    }
  }

  useEffect(() => {
    getUserSettingsData();
  }, []);

  const saveGeneralSettings = async (data) => {
    setsaving(true);
    let result = await updateBaseProfile(data);
    getUserSettingsData();
    setsaving(false);
    return result;
  };
  const saveProfileSetup = async (data) => {
    setsaving(true);
    let result = await updateProfileDetails(data);
    getUserSettingsData();
    setsaving(false);
    return result;
  };
  const savePasswordSettings = async (data) => {
    setsaving(true);
    let result = await updatePasswordSettings(data);
    setsaving(false);
    return result;
  };

  const saveLooksPersonality = async (data) => {
    setsaving(true);
    let result = await updateUserLooksPersonality(data);
    getUserSettingsData();
    setsaving(false);
    return result;
  };

  const saveLifestyleFavourites = async (data) => {
    setsaving(true);
    let result = await updateLifestyleFavourites(data);
    getUserSettingsData();
    setsaving(false);
    return result;
  };

  const saveProfilePicture = async (data) => {
    setsaving(true);
    let result = await updateProfilePic(data);
    dispatch(setProfilePic(result.data));
    console.log(result);
    getUserSettingsData();
    setsaving(false);
    return result;
  };

  const saveSocialLinks = async (data) => {
    setsaving(true);
    let result = await updateSocialLinks(data);
    getUserSettingsData();
    setsaving(false);
    return result;
  };

  const saveUserGallery = async (data) => {
    setsaving(true);
    let result = await updateUserGallery(data);
    getUserSettingsData();
    setsaving(false);
    return result;
  };

  return (
    <Suspense fallback={<PageSkeleton />}>
      <Template
        saveGeneralSettings={saveGeneralSettings}
        saveProfileSetup={saveProfileSetup}
        saveLooksPersonality={saveLooksPersonality}
        saveLifestyleFavourites={saveLifestyleFavourites}
        savePasswordSettings={savePasswordSettings}
        saveProfilePicture={saveProfilePicture}
        saveSocialLinks={saveSocialLinks}
        saveUserGallery={saveUserGallery}
        userSettingsData={userSettingsData}
        loading={loading}
        saving={saving}
        setupLoading={setupLoading}
        deleteMyAccount={deleteMyAccount}
        setupTypes={{
          profileSetupTypes,
          looksPersonalityTypes,
          lifestyleTypes,
        }}
      />
    </Suspense>
  );
}
export default ProfileSettings;
