import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Translations from "./translations";
import LanguageDetector from "i18next-browser-languagedetector";
import { BrowserRouter } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { splitLink, baseUrl } from "src/apollo";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getDomainMeta } from "src/themes";


const domainMeta = getDomainMeta();
document.title = domainMeta.communityName;


i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    resources: Translations,
    lng: domainMeta.defaultLang,
    fallbackLng: domainMeta.defaultLang,
    interpolation: {
      escapeValue: false,
    },
  });

export const apolloClient = new ApolloClient({
  link: splitLink,
  uri: baseUrl,
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ApolloProvider client={apolloClient}>
      <App />
    </ApolloProvider>
  </BrowserRouter>
);
