import { SET_LOADING ,SET_LOADING_FULL } from "./loadingTypes";

export const setLoading = (value) => {
  return {
    type: SET_LOADING,
    value: value,
  };
};

export const setLoadingFull = (value) => {
  return {
    type: SET_LOADING_FULL,
    value: value,
  };
};
