import { activeTheme } from "src/themes";
import { lazy, Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GET_PACKAGES } from "src/queries";
import { apolloClient } from "src/index";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";
import { PackageSkeleton } from "src/components/common/Skeletons";

const Template = lazy(() =>
  import(`src/templates/${activeTheme}/views/Packages.jsx`)
);
function Packages() {
  const { loading, error, data } = useQuery(GET_PACKAGES);
  const [getPackage, setGetPackage] = useState({
    id: "",
    packageId: "",
    packageName: "",
    showPurchase: "",
  });

  const userLocation = useSelector((state) => state.user.location);
  const continent = userLocation?.continent;
  const country_name = userLocation?.country_name;
  const {
    location: {
      country: { short_name },
    },
  } = userLocation;

  const [currency, setCurrency] = useState("");

  useEffect(() => {
    if (userLocation) {
      if (
        continent === "Europe" &&
        (country_name === "United Kingdom" ||
          short_name === "GB" ||
          short_name === "UK")
      ) {
        setCurrency("£");
        return;
      }
      if (continent === "Europe" && country_name !== "United Kingdom") {
        setCurrency("€");
        return;
      }
      setCurrency("$");
    }
  }, [country_name, continent, userLocation, short_name]);

  return (
    <Suspense fallback={<PackageSkeleton />}>
      <Template
        loading={loading}
        error={error}
        data={data}
        getPackage={getPackage}
        setGetPackage={setGetPackage}
        currency={currency}
      />
    </Suspense>
  );
}
export default Packages;
