import { type } from "@testing-library/user-event/dist/type";
import {
  SET_USER_DATA,
  UPDATE_SETUP_STEP,
  LOG_OUT_USER,
  CHNAGE_USERNAME,
  SET_COMMUNITY_DOMAIN,
  SET_UPDATE_PROFILE_STEP,
  SET_USER_FLIRTS,
  SET_PROFILE_PIC,
  SET_HOURLY_PACKAGE,
  SET_HOURLY_PACKAGE_IS_USED,
  SET_LOCATION,
  SET_USER_DISCOUNT,
  SET_USER_DISCOUNT_TIMER,
  SET_NEARBY_PLACES,
  SET_USER_PROFILE_PHOTO
} from "./userTypes";

export const setUserData = (userData, token, credit, profileSetupStep) => {
  return {
    type: SET_USER_DATA,
    userData: userData,
    token: token,
    credit: credit,
    profileSetupStep: profileSetupStep,
  };
};

export const setUserFlirts = (balance) => {
  return {
    type: SET_USER_FLIRTS,
    balance: balance,
  };
};

export const setProfilePic = (url) => {
  return {
    type: SET_PROFILE_PIC,
    url: url,
  };
};

export const updateSetupStep = (step) => {
  return {
    type: UPDATE_SETUP_STEP,
    stepCount: step,
  };
};

export const logoutUser = () => {
  return {
    type: LOG_OUT_USER,
  };
};

export const changeUserName = () => {
  return {
    type: CHNAGE_USERNAME,
  };
};

export const setCommunityDomain = (domain) => {
  return {
    type: SET_COMMUNITY_DOMAIN,
    domain: domain,
  };
};

export const setHourlyPackage = (isUsed, createdStamp) => {
  return {
    type: SET_HOURLY_PACKAGE,
    isUsed: isUsed,
    createdStamp: createdStamp,
  };
};

export const setHourlyPackageIsUsed = (isUsed) => {
  return {
    type: SET_HOURLY_PACKAGE_IS_USED,
    hourlyPackageIsUsed: isUsed,
  };
};

export const setUpdateProfileStep = (profileSetupStep) => {
  return {
    type: SET_UPDATE_PROFILE_STEP,
    profileSetupStep: profileSetupStep,
  };
};

export const setLocation = (location) => ({
  type: SET_LOCATION,
  location: location,
});

export const setUserDiscount = (userDiscount) => ({
  type: SET_USER_DISCOUNT,
  userDiscount: userDiscount,
});

export const setUserDiscountTimer = (setUserDiscountTimer) => ({
  type: SET_USER_DISCOUNT_TIMER,
  setUserDiscountTimer: setUserDiscountTimer,
});

export const setNearbyPlaces = (nearbyPlaces) => ({
  type: SET_NEARBY_PLACES,
  nearbyPlaces: nearbyPlaces,
});

export const setUserProfilePhoto = (profilePic) => ({
  type: SET_USER_PROFILE_PHOTO,
  profilePic,
});