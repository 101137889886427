import "src/assets/css/web.scss";
import { Routes, Route, Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Landing from "src/views/Web/Landing"
import RegisterPage from "src/templates/Basic/views/RegisterPage";
import Login from "src/views/Web/Login";
import Terms from "src/templates/Basic/views/Terms";
import Imprint from "src/templates/Basic/views/Imprint";
import Privacy from "src/templates/Basic/views/Privacy";
function WebRouter() {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);

  useEffect(() => {
    if (userData.token) {
      navigate("/find-matches");
    }
  }, [userData]);
  
  return (
    <Routes>
      <Route exact path="/" element={<Landing />} />
      <Route exact path="/register" element={<RegisterPage />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/terms" element={<Terms />} />
      <Route exact path="/imprint" element={<Imprint />} />
      <Route exact path="/privacy" element={<Privacy />} />
    </Routes>
  );
}
export default WebRouter;
