import React, { useState, useRef, useEffect } from "react";
import { useLanguage } from "src/hooks/useLanguage";
import { BiSolidUpArrow } from "react-icons/bi";
import { getDomainMeta } from "src/themes";


const languageOptions = [
  {
    key: "en",
    text: "English",
    flag: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
      >
        <rect
          x="1"
          y="4"
          width="30"
          height="24"
          rx="4"
          ry="4"
          fill="#fff"
        ></rect>
        <path
          d="M1.638,5.846H30.362c-.711-1.108-1.947-1.846-3.362-1.846H5c-1.414,0-2.65,.738-3.362,1.846Z"
          fill="#a62842"
        ></path>
        <path
          d="M2.03,7.692c-.008,.103-.03,.202-.03,.308v1.539H31v-1.539c0-.105-.022-.204-.03-.308H2.03Z"
          fill="#a62842"
        ></path>
        <path fill="#a62842" d="M2 11.385H31V13.231H2z"></path>
        <path fill="#a62842" d="M2 15.077H31V16.923000000000002H2z"></path>
        <path fill="#a62842" d="M1 18.769H31V20.615H1z"></path>
        <path
          d="M1,24c0,.105,.023,.204,.031,.308H30.969c.008-.103,.031-.202,.031-.308v-1.539H1v1.539Z"
          fill="#a62842"
        ></path>
        <path
          d="M30.362,26.154H1.638c.711,1.108,1.947,1.846,3.362,1.846H27c1.414,0,2.65-.738,3.362-1.846Z"
          fill="#a62842"
        ></path>
        <path d="M5,4h11v12.923H1V8c0-2.208,1.792-4,4-4Z" fill="#102d5e"></path>
        <path
          d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
          opacity=".15"
        ></path>
        <path
          d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
          fill="#fff"
          opacity=".2"
        ></path>
        <path
          fill="#fff"
          d="M4.601 7.463L5.193 7.033 4.462 7.033 4.236 6.338 4.01 7.033 3.279 7.033 3.87 7.463 3.644 8.158 4.236 7.729 4.827 8.158 4.601 7.463z"
        ></path>
        <path
          fill="#fff"
          d="M7.58 7.463L8.172 7.033 7.441 7.033 7.215 6.338 6.989 7.033 6.258 7.033 6.849 7.463 6.623 8.158 7.215 7.729 7.806 8.158 7.58 7.463z"
        ></path>
        <path
          fill="#fff"
          d="M10.56 7.463L11.151 7.033 10.42 7.033 10.194 6.338 9.968 7.033 9.237 7.033 9.828 7.463 9.603 8.158 10.194 7.729 10.785 8.158 10.56 7.463z"
        ></path>
        <path
          fill="#fff"
          d="M6.066 9.283L6.658 8.854 5.927 8.854 5.701 8.158 5.475 8.854 4.744 8.854 5.335 9.283 5.109 9.979 5.701 9.549 6.292 9.979 6.066 9.283z"
        ></path>
        <path
          fill="#fff"
          d="M9.046 9.283L9.637 8.854 8.906 8.854 8.68 8.158 8.454 8.854 7.723 8.854 8.314 9.283 8.089 9.979 8.68 9.549 9.271 9.979 9.046 9.283z"
        ></path>
        <path
          fill="#fff"
          d="M12.025 9.283L12.616 8.854 11.885 8.854 11.659 8.158 11.433 8.854 10.702 8.854 11.294 9.283 11.068 9.979 11.659 9.549 12.251 9.979 12.025 9.283z"
        ></path>
        <path
          fill="#fff"
          d="M6.066 12.924L6.658 12.494 5.927 12.494 5.701 11.799 5.475 12.494 4.744 12.494 5.335 12.924 5.109 13.619 5.701 13.19 6.292 13.619 6.066 12.924z"
        ></path>
        <path
          fill="#fff"
          d="M9.046 12.924L9.637 12.494 8.906 12.494 8.68 11.799 8.454 12.494 7.723 12.494 8.314 12.924 8.089 13.619 8.68 13.19 9.271 13.619 9.046 12.924z"
        ></path>
        <path
          fill="#fff"
          d="M12.025 12.924L12.616 12.494 11.885 12.494 11.659 11.799 11.433 12.494 10.702 12.494 11.294 12.924 11.068 13.619 11.659 13.19 12.251 13.619 12.025 12.924z"
        ></path>
        <path
          fill="#fff"
          d="M13.539 7.463L14.13 7.033 13.399 7.033 13.173 6.338 12.947 7.033 12.216 7.033 12.808 7.463 12.582 8.158 13.173 7.729 13.765 8.158 13.539 7.463z"
        ></path>
        <path
          fill="#fff"
          d="M4.601 11.104L5.193 10.674 4.462 10.674 4.236 9.979 4.01 10.674 3.279 10.674 3.87 11.104 3.644 11.799 4.236 11.369 4.827 11.799 4.601 11.104z"
        ></path>
        <path
          fill="#fff"
          d="M7.58 11.104L8.172 10.674 7.441 10.674 7.215 9.979 6.989 10.674 6.258 10.674 6.849 11.104 6.623 11.799 7.215 11.369 7.806 11.799 7.58 11.104z"
        ></path>
        <path
          fill="#fff"
          d="M10.56 11.104L11.151 10.674 10.42 10.674 10.194 9.979 9.968 10.674 9.237 10.674 9.828 11.104 9.603 11.799 10.194 11.369 10.785 11.799 10.56 11.104z"
        ></path>
        <path
          fill="#fff"
          d="M13.539 11.104L14.13 10.674 13.399 10.674 13.173 9.979 12.947 10.674 12.216 10.674 12.808 11.104 12.582 11.799 13.173 11.369 13.765 11.799 13.539 11.104z"
        ></path>
        <path
          fill="#fff"
          d="M4.601 14.744L5.193 14.315 4.462 14.315 4.236 13.619 4.01 14.315 3.279 14.315 3.87 14.744 3.644 15.44 4.236 15.01 4.827 15.44 4.601 14.744z"
        ></path>
        <path
          fill="#fff"
          d="M7.58 14.744L8.172 14.315 7.441 14.315 7.215 13.619 6.989 14.315 6.258 14.315 6.849 14.744 6.623 15.44 7.215 15.01 7.806 15.44 7.58 14.744z"
        ></path>
        <path
          fill="#fff"
          d="M10.56 14.744L11.151 14.315 10.42 14.315 10.194 13.619 9.968 14.315 9.237 14.315 9.828 14.744 9.603 15.44 10.194 15.01 10.785 15.44 10.56 14.744z"
        ></path>
        <path
          fill="#fff"
          d="M13.539 14.744L14.13 14.315 13.399 14.315 13.173 13.619 12.947 14.315 12.216 14.315 12.808 14.744 12.582 15.44 13.173 15.01 13.765 15.44 13.539 14.744z"
        ></path>
      </svg>
    ),
    value: "en",
  },
  {
    key: "de",
    text: "Deutsch",
    flag: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
      >
        <path fill="#cc2b1d" d="M1 11H31V21H1z"></path>
        <path d="M5,4H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"></path>
        <path
          d="M5,20H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
          transform="rotate(180 16 24)"
          fill="#f8d147"
        ></path>
        <path
          d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
          opacity=".15"
        ></path>
        <path
          d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
          fill="#fff"
          opacity=".2"
        ></path>
      </svg>
    ),
    value: "de",
  },
  {
    key: "ch",
    text: "Chinese",
    flag: (
      <svg  width="32"
      height="32" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 55.2 38.4" style={{enableBackground: 'new 0 0 55.2 38.4'}} xmlSpace="preserve"><style type="text/css" dangerouslySetInnerHTML={{__html: ".st0{fill:#DE2910;} .st1{fill:#FFDE00;}" }} /><g><path className="st0" d="M3.01,0h49.17c1.66,0.01,3.01,1.37,3.01,3.03v32.33c0,1.66-1.35,3.02-3.01,3.03L3,38.4 c-1.65-0.02-3-1.38-3-3.03V3.03C0,1.37,1.35,0.01,3.01,0L3.01,0z" /><polygon className="st1" points="8.4,3.84 11.79,14.26 2.92,7.82 13.88,7.82 5.01,14.26 8.4,3.84" /><polygon className="st1" points="18.75,2.07 18.43,5.71 16.55,2.58 19.91,4.01 16.35,4.83 18.75,2.07" /><polygon className="st1" points="23.22,6.34 21.51,9.57 20.99,5.96 23.54,8.58 19.94,7.95 23.22,6.34" /><polygon className="st1" points="23.64,12.78 20.77,15.03 21.77,11.52 23.02,14.95 19.99,12.91 23.64,12.78" /><polygon className="st1" points="18.68,15.48 18.51,19.13 16.5,16.08 19.92,17.37 16.4,18.34 18.68,15.48" /></g></svg>
    ),
    value: "ch",
  },
];

const LanguageSelector = () => {
  const ref = useRef(null);
  const { currentLanguage, changeLanguage } = useLanguage();
  const [selectedOption, setSelectedOption] = useState(currentLanguage);
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (value) => {
    setSelectedOption(value);
    changeLanguage(value);
    setIsOpen(false);
  };

  const selectedLanguage = languageOptions.find(
    (option) => option.value === selectedOption
  );

  useEffect(() => {
    const handleOutSideClick = (event) => {
      if (!ref.current?.contains(event.target)) {
        setIsOpen(false);
      }
    };
    ["mousedown", "scroll"].forEach((event) =>
      window.addEventListener(event, handleOutSideClick)
    );
    return () => {
      ["mousedown", "scroll"].forEach((event) =>
        window.addEventListener(event, handleOutSideClick)
      );
    };
  }, [ref]);
  if(!getDomainMeta().useLangSwitch) return 
  
  return (
    <div>
      <div className="relative">
        <button
          type="button"
          className="relative w-full cursor-default rounded-full bg-white py-1 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
          aria-haspopup="listbox"
          aria-expanded={isOpen}
          aria-labelledby="listbox-label"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="flex items-center">
            {selectedLanguage.flag}
            <span className="ml-2 phone:hidden lg:block truncate cursor-pointer">
              {selectedLanguage.text}
            </span>
          </span>
          <span style={{zIndex:'99999999'}} className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
            <svg
              className="h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </button>
        {isOpen && (
          <ul
            ref={ref}
            className="absolute z-10 mt-3 max-h-56 w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm p-4 phone:right-[0%] sm:right-0"
            role="listbox"
            aria-labelledby="listbox-label"
            style={{zIndex:'99999999'}}
          >

            <BiSolidUpArrow color="white" size={20} className="absolute right-2 top-[-12px]" />

            {languageOptions.map((option) => (
              <li
                key={option.key}
                className={`relative cursor-default select-none py-2 ${option.value === selectedOption ? "" : "text-gray-900"
                  }`}
                role="option"
                onClick={() => handleSelect(option.value)}
              >
                <div className="flex items-center cursor-pointer">
                  {option.flag}
                  <span
                    className={`ml-2 phone:hidden sm:block truncate ${option.value === selectedOption
                      ? "font-semibold"
                      : "font-normal"
                      }`}
                  >
                    {option.text}
                  </span>
                </div>

                {/* {option.value === selectedOption && (
                  <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600">
            
                  </span>
                )} */}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default LanguageSelector;
