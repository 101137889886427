import ChatModule from 'src/components/Main/ChatModule'
 
function Chat() {
  return (
    <>
      <ChatModule type="main_chat" className=" "  />
    </>
  )
}

export default Chat

