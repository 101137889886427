import { useCallback, useState } from "react";
import { apolloClient } from "src/index";
import { SEND_MESSAGE } from "src/queries";
import { useDispatch } from "react-redux";
import { setUserFlirts } from "src/redux/index";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {setFlirtonPopup} from 'src/redux/index'
function useSendMessage() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const sendMessage = useCallback(async (messageData) => {
    let {
      body,
      user,
      attachment,
      messageFile,
      giftId,
      message_type,
      attachment_url,
    } = messageData;
    try {
      let input = {
        body: body,
        user: user,
        attachment: "",
        message_type: message_type,
      };
      if (giftId) {
        input.message_type = "g";
        input.gift_id = giftId;
        input.attachment_url = attachment_url;
      }
      setLoading(true);
      const { data, errors } = await apolloClient.mutate({
        mutation: SEND_MESSAGE,
        variables: {
          input: input,
          // file: messageFile,
        },
      });
      setLoading(false);
      dispatch(setUserFlirts(data.sendMessage.flirts));
      return {
        success: true,
        data: data,
      };
    } catch (e) {
      dispatch(setFlirtonPopup(true))
      setLoading(false);
      console.log(e);
      return {
        success: false,
        error: e,
      };
    }
  });

  return [loading, sendMessage];
}

export { useSendMessage };
