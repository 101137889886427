import { activeTheme } from "src/themes";
import {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import { apolloClient } from "src/index";
import { SEND_MESSAGE } from "src/queries";
import { useSendMessage } from "src/hooks/useSendMessage";
import EventEmitter from "src/event";
import { ChatBoxSkeleton } from "src/components/common/Skeletons";
const QucikTemplate = lazy(() =>
  import(`src/templates/${activeTheme}/components/Main/Sub/QuickChatBox.jsx`)
);
const Template = lazy(() =>
  import(`src/templates/${activeTheme}/components/Main/Sub/ChatBox.jsx`)
);

function ChatBox(props) {
  const {
    userData,
    selectedConversation,
    type,
    unselectConversation,
    setSelectedConversation,
    readMessages,
    gettingMessagesLoading,
    buyNude,
    getGiftsHandler,
    giftIsLoading,
    giftData,
    showGifts,
    setShowGifts
  } = props;
  const [loading, sendMessage] = useSendMessage();

  console.log(selectedConversation, "SELECTED CONVERSATION");

  useEffect(() => {
    console.log("SETUP MESSAGE_LISTEN");
    EventEmitter.addListener("MESSAGE_LISTEN", ({ data }) => {
      console.log("MESSAGE_LISTEN from chat boxx", data.data.messageAdded.mes);
    
      let incomingMessageUsername = data.data.messageAdded.mes.sender.username;
      let loggedInUserUsername = userData.userData.username;
      let openedTargetUsername = selectedConversation.convo.m_one.username;
      if (
        incomingMessageUsername == loggedInUserUsername ||
        incomingMessageUsername == openedTargetUsername
      ) {
        setSelectedConversation((prev) => {
          let netMessages = [...prev.messages, data.data.messageAdded.mes];
          return {
            convo: prev.convo,
            messages: netMessages,
          };
        });
        if (incomingMessageUsername == openedTargetUsername) {
          readMessages([data.data.messageAdded.mes.id]);
        }
      } else {
        // alert("from another convo")
      }
    });
    return () => {
      console.log("remove MESSAGE_LISTEN");
      EventEmitter.removeAllListeners();
    };
  }, [selectedConversation]);

  return (
    <Suspense fallback={<ChatBoxSkeleton />}>
      {type === "quick_chat" ? (
        <QucikTemplate
          userData={userData}
          selectedConversation={selectedConversation}
          unselectConversation={unselectConversation}
          sendMessage={sendMessage}
          loading={loading}
        />
      ) : (
        <Template
          sendMessage={sendMessage}
          userData={userData}
          selectedConversation={selectedConversation}
          unselectConversation={unselectConversation}
          loading={loading}
          gettingMessagesLoading={gettingMessagesLoading}
          buyNude={buyNude}
          getGiftsHandler={getGiftsHandler}
          giftIsLoading={giftIsLoading}
          giftData={giftData}
          showGifts={showGifts}
          setShowGifts={setShowGifts}
        />
      )}
    </Suspense>
  );
}

export default ChatBox;
