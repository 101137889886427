import { activeTheme } from "src/themes";
import { lazy, Suspense } from "react";
import { apolloClient } from "src/index";
import { USER_LOGIN } from "src/queries";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setUserData } from "src/redux/index";
import { setLoading } from "src/redux/index";
import { PageSkeleton } from "src/components/common/Skeletons";
import { setHourlyPackageIsUsed } from "src/redux/user/userActions";
import { setMessageModal } from "src/redux/message/messageActions";

const Template = lazy(() =>
  import(`src/templates/${activeTheme}/views/Login.jsx`)
);

function Login() {
  const dispatch = useDispatch();

  const loginUser = async (username, password) => {
    try {
      dispatch(setLoading(true));
      const { data, errors } = await apolloClient.mutate({
        mutation: USER_LOGIN,
        variables: {
          usernameOrEmail: username,
          password: password,
          addr: "123.123.12.3",
        },
      });
      dispatch(setLoading(false));

      let userData = data.login;

      console.log(userData.user, "USER DATA");
      if (userData.errors) {
        let errorMessage = userData.errors[0].message;
        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 1000,
        });
        return {
          success: false,
          message: errorMessage,
        };
      }
      if (userData.token) {
        dispatch(setHourlyPackageIsUsed(userData.user.used_hpass));
        dispatch(setUserData(userData.user, userData.token, userData.credit));
        setTimeout(() => {
          window.location.href = "/find-matches";
        }, 1000);

        dispatch(setMessageModal(true));
      }
      return {
        success: true,
        message: "success",
      };
    } catch (e) {
      dispatch(setLoading(false));
      alert(e);
    }
  };

  return (
    <Suspense fallback={<PageSkeleton />}>
      <Template loginUser={loginUser} />
    </Suspense>
  );
}

export default Login;
