import { SET_CHAT , SET_START_CHAT_MODAL } from "./chatTypes";

export const setChat = (user,chatType) => {
  return {
    type: SET_CHAT,
    user: user,
    chatType:chatType
  };
};

export const setChatModal = (data) => {
  return {
    type: SET_START_CHAT_MODAL,
    data:data
  };
};
