import { activeTheme } from "src/themes";
import { lazy, Suspense } from "react";
import { useSelector } from "react-redux";

const Template = lazy(() =>
  import(`src/templates/${activeTheme}/components/Layout/AppFooter.jsx`)
);

function AppFooter() {
  const appData = useSelector((state) => state.app);

  return (
    <Suspense fallback={<p>Loading</p>}>
      <Template appData={appData} />
    </Suspense>
  );
}
export default AppFooter;
