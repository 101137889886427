import UserConversations from "src/components/Main/Sub/UserConversations";
import ChatBox from "src/components/Main/Sub/ChatBox";
import { useEffect } from "react";
import EventEmitter from "src/event";
import { playSound } from "src/components/common/AudioMedia";
import { IMAGE_BASE_URL } from "src/utils/constants";
import { themeStyle } from "src/themes";

function MainChat(props) {
  let screenWidth = window.innerWidth;
  const {
    readMessages,
    conversationExists,
    unreadMessages,
    setUnreadMessages,
    selectedConversation,
    setSelectedConversation,
    userData,
    chat,
    dispatch,
    getUnreadMessages,
    getConversationMessages,
    gettingMessagesLoading,
    buyNude,
    getGiftsHandler,
    giftIsLoading,
    giftData,
    showGifts,
    setShowGifts,
  } = props;

  const unselectConversation = () => {
    setSelectedConversation(null);
  };
  const conversationSelectEvent = async (convo) => {
    getConversationMessages(convo.id);
  };

  return (
    <div className={`${screenWidth > 400 ? "container" : ""}`}>
      <div className=" flex  antialiased text-gray-800">
        <div className="flex flex-col sm:flex-row w-full ">
          <div className="flex flex-col py-8 pl-2 px-2 pr-2 w-full sm:w-64 bg-white flex-shrink-0">
            <div className="flex flex-row items-center justify-center h-12 w-full">
              <div
                style={{
                  color: themeStyle.background,
                }}
                className="flex items-center justify-center rounded-2xl bg-red-100 h-10 w-10"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
                  />
                </svg>
              </div>
              <div className="ml-2 font-bold text-2xl font-sans uppercase">
                FlirtTool
              </div>
            </div>
            <div
              style={{ backgroundColor: themeStyle.background }}
              className="flex flex-col items-center  border border-gray-200 mt-4 w-full py-6 px-4 rounded-lg"
            >
              <div className="h-20 w-20 rounded-full border overflow-hidden">
                <img
                  src={
                    IMAGE_BASE_URL +
                    selectedConversation?.convo.m_one?.profilePic
                  }
                  alt="Avatar"
                  className="h-full w-full"
                />
              </div>
              <div className="text-sm text-white font-semibold mt-2">
                {selectedConversation?.convo.m_one.username}
              </div>
              <div className="flex flex-row items-center mt-3">
                <div className="flex flex-col justify-center h-4 w-8 bg-green-500 rounded-full animate-pulse">
                  <div className="h-3 w-3 bg-white rounded-full self-end mr-1" />
                </div>
                <div className="leading-none text-white ml-1 text-xs">
                  Active
                </div>
              </div>
            </div>
            <UserConversations
              type={"main_chat"}
              conversationSelectEvent={conversationSelectEvent}
              unreadMessages={unreadMessages}
              getUnreadMessages={getUnreadMessages}
            />
            {/* <Conversations/> */}
          </div>
          <ChatBox
            selectedConversation={selectedConversation}
            userData={userData}
            type={"main_chat"}
            unselectConversation={unselectConversation}
            setSelectedConversation={setSelectedConversation}
            readMessages={readMessages}
            gettingMessagesLoading={gettingMessagesLoading}
            buyNude={buyNude}
            getGiftsHandler={getGiftsHandler}
            giftIsLoading={giftIsLoading}
            giftData={giftData}
            showGifts={showGifts}
            setShowGifts={setShowGifts}
          />
        </div>
      </div>
    </div>
  );
}

export default MainChat;
