import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLocation, setNearbyPlaces } from "src/redux/user/userActions";
import { production } from "src/apollo";
import { GET_USER_PLACES } from "src/queries";
import { apolloClient } from "src/index";
import Countries from "./Countries";
let API_KEY = "AIzaSyDOkFPJ7laX0Tsy0QehCHQaN2UBrRvr_gI";
//http://api.geonames.org/findNearbyPlaceNameJSON?lat=47.3&lng=9&username=sumit9807
let getNearbyPlacesBase = production
  ? "https://api.geonames.org/"
  : "http://api.geonames.org/";
export const getNearbyPlaces = async (lat, lng) => {
  try {
    let places = [];
    const { data, errors } = await apolloClient.query({
      query: GET_USER_PLACES,
      variables: {
        lat: String(lat),
        lng: String(lng),
      },
    });
    if (data.getUserPlaces) {
      let usePlaces = JSON.parse(data.getUserPlaces);
      usePlaces.geonames.forEach((place) => {
        places.push(place.name);
      });
    }
    return places;
  } catch (e) {
    console.log(e);
    console.log(e.message);
  }
};

const reverseGeocode = async (lat, lng) => {
  // alert("reverseGeocode")
  let response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${API_KEY}`,
    {
      method: "POST",
    }
  );
  let data = await response.json();
  let parsedAddress = parseAddress(data);
  return parsedAddress;
};

const getIpAddress = async () => {
  let response = await fetch("https://api.ipify.org?format=json");
  let data = await response.json();
  return data.ip;
};

const mapIpToLatiAndLongi = async (ipAddress) => {
  let response = await fetch(`https://ipwhois.app/json/${ipAddress}`);
  let data = await response.json();
  return data;
};

const parseAddress = (response) => {
  let results = response.results;
  let parseAddress = results.reduce(
    (accumulator, obj) => {
      let componentCountainsCountry = obj.types.includes("country");
      let componentCountainsPostalCode = obj.types.includes("postal_code");

      if (componentCountainsCountry) {
        let address_components = obj.address_components;
        let countryComponenet = address_components.find((c) =>
          c.types.includes("country")
        );
        accumulator.country = countryComponenet;
      }

      if (componentCountainsPostalCode) {
        let address_components = obj.address_components;
        let postalComponenet = address_components.find(
          (c) => c.types.includes("postal_code") && c.types.length == 1
        );
        if (postalComponenet) {
          accumulator.postal_code = postalComponenet.short_name;
          accumulator.street_address = obj.formatted_address;
        }
      }
      return accumulator;
    },
    {
      country: null,
      postal_code: null,
      street_address: null,
    }
  );
  return parseAddress;
};
// uk -> pound
// eur -> ruot
// dollar
//curl -L -X GET 'https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=-33.8670522%2C151.1957362&radius=1500&type=restaurant&keyword=cruise&key=AIzaSyCkktP7eEWHs8FJJ6hFe10co5etxojmnKA'
const useLocation = () => {
  const dispatch = useDispatch();
  const [locationSet, setLocationSet] = useState(false);
  const [permissionState, setPermissionState] = useState("prompt");
  const userLocation = useSelector((state) => state.user.location);
  const userPlaces = useSelector((state) => state.user.nearbyPlaces);

  const googleGeolocation = async () => {
    let response = await fetch(
      `https://www.googleapis.com/geolocation/v1/geolocate?key=${API_KEY}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          considerIp: true,
        }),
      }
    );
    let data = await response.json();
    return data;
  };

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            let ipAddress = await getIpAddress();
            let { latitude, longitude, continent } = await mapIpToLatiAndLongi(
              ipAddress
            );
            // const { latitude, longitude } = position.coords;
            let address = await reverseGeocode(latitude, longitude);
            let short_name = address.country.short_name;
            let long_name = address.country.long_name;
            let flag = Countries().find((c) => c.code == short_name).flag;
            address.country_flag = flag;
            let locationObject = {
              country_name: address.country.long_name,
              lat: latitude,
              lng: longitude,
              location: address,
              source: "HTML geolocation API",
              continent: continent,
              currency:
                continent === "Europe" &&
                (long_name === "United Kingdom" ||
                  short_name === "GB" ||
                  short_name === "UK")
                  ? "gbp"
                  : continent === "Europe" && long_name !== "United Kingdom"
                  ? "eur"
                  : "usd",
            };
            dispatch(setLocation(locationObject));
            console.log(latitude, "longitudelongitudelongitudelongitude");
            console.log(longitude, "longitudelongitude");
            let places = await getNearbyPlaces(latitude, longitude);
            dispatch(setNearbyPlaces(places));
            console.log(places);
            setLocationSet(() => true);
          },
          async (error) => {
            console.error("Geolocation error:", error);
            // alert(`Geolocation error: ${error.message}`);
            // let location = await googleGeolocation();
            // let latitude = location.location.lat;
            // let longitude = location.location.lng;
            let ipAddress = await getIpAddress();
            let { latitude, longitude, continent } = await mapIpToLatiAndLongi(
              ipAddress
            );

            let address = await reverseGeocode(latitude, longitude);

            let short_name = address.country.short_name;
            let long_name = address.country.long_name;
            let flag = Countries().find((c) => c.code == short_name).flag;
            address.country_flag = flag;
            let locationObject = {
              country_name: address.country.long_name,
              lat: latitude,
              lng: longitude,
              location: address,
              source: "Google Geolocation API",
              continent: continent,
              currency:
                continent === "Europe" &&
                (long_name === "United Kingdom" ||
                  short_name === "GB" ||
                  short_name === "UK")
                  ? "gbp"
                  : continent === "Europe" && long_name !== "United Kingdom"
                  ? "eur"
                  : "usd",
            };
            dispatch(setLocation(locationObject));
            let places = await getNearbyPlaces(latitude, longitude);
            dispatch(setNearbyPlaces(places));
            setLocationSet(() => true);
            // console.log(location, "locationlocationlocation");
          }
        );
      } catch (error) {
        console.error("Permission query error:", error);
      }
    };
    if (!userLocation || !userPlaces?.length) {
      fetchLocation();
    }
  }, [dispatch, permissionState]);

  return {
    locationSet,
    permissionState,
  };
};

export default useLocation;
