import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { getUserImage } from "src/utils/helpers";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { logoutUser } from "src/redux";

const MobileAppMenu = ({ toggleSidebar, isOpen }) => {
  const { t } = useTranslation();
  const userData = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleLinkClick = (path) => {
    toggleSidebar();
    setTimeout(() => {
      navigate(path);
    }, 300);
  };

  const linkClasses = (path) =>
    `p-2.5 mt-2 flex items-center rounded-md px-4 duration-300 cursor-pointer ${location.pathname === path ? "bg-[#FF69B4]" : "hover:bg-[#FF69B4]"
    }`;

  return (
    <div className="sm:hidden relative">
      <span
        className="absolute text-white text-4xl top-5 right-4 cursor-pointer"
        onClick={toggleSidebar}
      ></span>
      <div
        className="fixed top-0 bottom-0 duration-1000 p-2 w-[300px] overflow-y-auto text-center bg-gray-900 shadow h-screen"
        style={{
          right: isOpen ? "0" : "-300px",
          zIndex: 9999,
          backgroundColor: "#333",
          transition: "right 0.3s ease-in-out",
        }}
      >
        <div className="text-gray-100 text-xl">
          <span
            className="absolute text-white text-4xl top-5 right-6 cursor-pointer"
            onClick={toggleSidebar}
          >
            <i className="fa fa-xmark fa-xs"></i>
          </span>
          <div className="flex items-center gap-2 p-2">
            <div>
              <img
                src={getUserImage(userData.userData.profilePic)}
                className="w-14 h-14 rounded-full object-cover"
                alt=""
              />
            </div>
            <div className="flex flex-col items-baseline">
              <strong className="text-base">
                {userData.userData.username}
              </strong>
              <small className="whitespace-nowrap text-sm">
                {userData.credit.balance} {t("Coins")}
              </small>
            </div>
          </div>

          <hr className="my-2 text-gray-600" />
          <div className="mt-8">
            <div
              onClick={() => handleLinkClick("/find-matches")}
              className={linkClasses("/find-matches")}
            >
              <span className="flex items-center cursor-pointer">
                <i className="fa fa-home text-white" />
                <span className="text-base ml-4 text-gray-200">
                  {t("Home")}
                </span>
              </span>
            </div>
            <div
              onClick={() => handleLinkClick("/profile-settings")}
              className={linkClasses("/profile-settings")}
            >
              <span className="flex items-center cursor-pointer">
                <i className="fa fa-user text-white" />
                <span className="text-base ml-4 text-gray-200">Profile</span>
              </span>
            </div>
            <div
              onClick={() => handleLinkClick("/chat")}
              className={linkClasses("/chat")}
            >
              <span className="flex items-center cursor-pointer">
                <i className="fa-solid fa-comment text-white" />
                <span className="text-base ml-4 text-gray-200">Chat</span>
              </span>
            </div>
            <div
              onClick={() => handleLinkClick("/liked")}
              className={linkClasses("/liked")}
            >
              <span className="flex items-center cursor-pointer">
                <i className="fa-solid fa-heart text-white" />
                <span className="text-base ml-4 text-gray-200">
                  Liked Profile
                </span>
              </span>
            </div>
            <div
              onClick={() => handleLinkClick("/disliked")}
              className={linkClasses("/disliked")}
            >
              <span className="flex items-center cursor-pointer">
                <i className="fa-solid fa-heart-crack text-white" />
                <span className="text-base ml-4 text-gray-200">
                  Disliked Profile
                </span>
              </span>
            </div>
            <div
              onClick={() => handleLinkClick("/flirts")}
              className={linkClasses("/flirts")}
            >
              <span className="flex items-center cursor-pointer">
                <i className="fa fa-database text-white" />
                <span className="text-base ml-4 text-gray-200">Flirts</span>
                <span className="whitespace-nowrap ml-2 text-base">
                  {userData.credit.balance} {t("Coins")}
                </span>
              </span>
            </div>

            <div
              onClick={() => handleLinkClick("/purchase-history")}
              className={linkClasses("/purchase-history")}
            >
              <span className="flex items-center cursor-pointer">
                <i className="fa fa-shopping-cart text-white" />
                <span className="text-base ml-4 text-gray-200">Purchase History</span>
                {/* <span className="whitespace-nowrap ml-4 text-sm">
                  {userData.credit.balance} {t("Coins")}
                </span> */}
              </span>
            </div>
            <div
              className="mt-8 bg-pink-500 rounded-sm p-2 text-base"
              onClick={(e) => {
                e.preventDefault();
                dispatch(logoutUser());
              }}
            >
              <span>Logout</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileAppMenu;
