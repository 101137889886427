import SouldatesImage from "src/assets/img/souldates1.jpeg";
import FlirttoolImage from "src/assets/img/flirttool.jpg";
import FlirtoolPrimeLogo from "src/assets/img/FlirttoolPrimeLogo.jpg";

export const appliedThemes = ["Basic", "Red", "Flirttol"];
//deeppink
const themeStyes = {
  Basic: {
    color: "#e24b57",
    background: "#e24b57",
    lightBackground: "#e24b57",
    lightBackground2: "#e24b57",
  },
};
export const communityMeta = {
  "flirttool.com": {
    landingImage: FlirttoolImage,
    communityName: "FlirtTool Prime",
    websiteLink: "https://flirttool.com",
    header_heading1: "Flirttool Prime",
    header_heading2: "FlirtTool Prime Dating Community",
    main_heading: "Flirttool Prime",
    domain: "Flirttool",
    mail: "support@flirttool.com",
    internet: "https://flirttool.com",
    address: "",
    support: "",
    addressline1: "Dialogmakers International Ltd.",
    addressline2: "71-75 Shelton Street, Covent Garden, London, WC2H 9JQ",
    flirtoolPrimeLogo: FlirtoolPrimeLogo,
    defaultLang:'en',
    useLangSwitch:true
  },
  
  "www.souldates.de": {
    landingImage: SouldatesImage,
    communityName: "Souldates",
    websiteLink: "https://www.souldates.de",
    header_heading1: "",
    header_heading2: "Soul Dates",
    main_heading: "Soul Dates",
    domain: "Soul Dates",
    mail: "support@souldates.de",
    internet: "https://www.souldates.de",
    address: "",
    support: "",
    addressline1: `fivecom GmbH
    Collegienstr. 69
    06886 Lutherstadt Wittenberg
    Deutschland`,
    addressline2: `Amtsgericht Stendal
    HRB 34236
    UST-ID: DE294516404
    Geschäftsführerin: Esther Fiege`,
    defaultLang:'de',
    useLangSwitch:false
  },
  "globalchat.us": {
    landingImage: SouldatesImage,
    communityName: "Souldates",
    websiteLink: "https://www.souldates.de",
    header_heading1: "Global",
    header_heading2: "Chat",
    main_heading: "Global Chat Dating",
    domain: "globalchat.us",
    mail: "support@globalchat.us",
    internet: "https://globalchat.us",
    address:
      "GWIK LLC, 5203 Juan Tabo Blvd. 4NE Suite 2b, Albuquerque, NM 87111 USA",
    support: "https://tawk.to/chat/60d8ca3565b7290ac6382e01/1f97dbp6v",
    addressline1: "GWIK LLC, 5203 Juan Tabo Blvd.",
    addressline2: "4NE Suite 2b, Albuquerque, NM 87111 USA",
    defaultLang:'en',
    useLangSwitch:true
  },
  "globechat.cn": {
    landingImage: SouldatesImage,
    communityName: "Globe CHat",
    websiteLink: "https://www.souldates.de",
    header_heading1: "Globe",
    header_heading2: "Chat",
    main_heading: "Globe Chat",
    domain: "globechat.cn",
    mail: "support@globalchat.us",
    internet: "https://globechat.cn",
    address:
      "GWIK LLC, 5203 Juan Tabo Blvd. 4NE Suite 2b, Albuquerque, NM 87111 USA",
    support: "https://tawk.to/chat/60d8ca3565b7290ac6382e01/1f97dbp6v",
    addressline1: "GWIK LLC, 5203 Juan Tabo Blvd.",
    addressline2: "4NE Suite 2b, Albuquerque, NM 87111 USA",
    defaultLang:'ch',
    useLangSwitch:false
  },
};

export const activeTheme = "Basic";
export const themeStyle = themeStyes[activeTheme];
export const getDomainMeta = () => {
  let domain = window.location.hostname;
  if (!communityMeta[[domain]]) return communityMeta["www.souldates.de"];
  return communityMeta[domain];
};
