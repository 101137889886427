import { Link } from "react-router-dom";
import { getDomainMeta } from "src/themes";
import Footer from "../components/Footer/Footer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function Privacy() {
  const { t } = useTranslation();
  const userData = useSelector((state) => state.user);
  const appData = useSelector((state) => state.app);
  const domainMeta = getDomainMeta();

  return (
    <>
      <div className="px-4 pt-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <div className="relative flex items-center justify-between">
          <a
            href="/"
            aria-label="Company"
            title="Company"
            className="indivne-flex items-center"
          >
            <img
              src={`https://mazutech.online/${appData.communityMeta?.logo}`}
              alt=""
              className=" object-cover w-32"
            />
          </a>
          <ul className="flex items-center lg:flex">
            <div>
              {!userData.token && (
                <Link to="/login">
                  <button
                    class="indivne-flex items-center justify-center h-12 px-6 font-medium tracking-wide bg-red-900 text-white transition duration-200 rounded shadow-md bg-deep-red-accent-400 hover:bg-deep-red-accent-700 focus:shadow-outdivne focus:outdivne-none"
                    aria-label="Sign up"
                  >
                    {t("Login")}
                  </button>
                </Link>
              )}
            </div>
          </ul>
        </div>

        <div
          className="
          py-12
          mx-auto
          sm:max-w-xl
          md:max-w-full
          lg:max-w-screen-xl
          md:px-24
          lg:px-8
          lg:py-20 flex items-center justify-center flex-col"
        >
          <div>
            <p className="pb-3 text-2xl sm:text-4xl text-center">
              <strong>{t("Privacy Policy and Terms")}</strong>
            </p>

            <div className="flex items-center flex-col justify-center">
              <span>{`${domainMeta.header_heading2}`}</span>
              <span>{`${domainMeta.addressline1}`}</span>
              <span>{`${domainMeta.addressline2}`}</span>
            </div>

            <div className="flex items-center flex-col justify-center">
              <button>
                {t("email")}: {`${domainMeta.mail}`}
              </button>
              <button>
                Internet:{" "}
                <span className="text-[#2563EB]">{`${domainMeta.internet}`}</span>{" "}
              </button>
            </div>
            <p className="block flex justify-center items-center">
              <span>{t("for the mobile app,")}</span>{" "}
              <a
                href={`${domainMeta.websiteLink}`}
                target="_blank"
                rel="noreferrer"
                className="text-[#2563EB] ml-1"
              >
                {domainMeta.communityName}{" "}
              </a>{" "}
             {t(", and the website")} {`${domainMeta.communityName}`}
              {t("as offered by")} {`${domainMeta.communityName}`}...
            </p>
          </div>

          <div className="flex items-center justify-center flex-col mt-10">
            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">
                  {t("Introduction")}
                </strong>
              </p>
              <p className="text-center">
                ({t("hereinafter")} “ {domainMeta.communityName}{" "}
                {t("..” or “we/us”")})
                {t(
                  "operates a platform and service for chatting, getting to know"
                )}
                {t("and meeting other people")} ({t("hereinafter")} “{" "}
                {domainMeta.communityName} ”) {t("under the domain")}
                {domainMeta.communityName}{" "}
                {t(".com and with the help of the mobile app")}
                {domainMeta.communityName} ({t("hereinafter")} “{" "}
                {domainMeta.communityName} app”),{" "}
                {t(
                  "which is available for download in various app stores for various operating systems."
                )}
              </p>

              <p className="text-center">
                {t("The user of")} {domainMeta.communityName}{" "}
                {t(
                  "(in the following: 'user' or 'you') can create a profile (in the following: 'user profile') free of charge via the"
                )}{" "}
                {domainMeta.communityName} {t("domain or")}{" "}
                {domainMeta.communityName}{" "}
                {t(
                  "app, upload pictures, and exchange information with other users and their user profiles in case of interest. The user has the possibility to use functions such as the chat, the flirt radar, or the match game, and many other functions of"
                )}{" "}
                {domainMeta.communityName}.{" "}
                {t("A registration is possible from the age of 18.")}
              </p>

              <p className="text-center">
                {t(
                  "Your data will be collected, processed and used in accordance with the EU-General Data Protection Regulation (GDPR) 2016/679 applicable as of May 25th 2018 and the related privacy laws."
                )}
              </p>

              <p className="text-center">
                {t(
                  "This privacy policy explains how we handle personal data. Personal data is individual information about the personal or factual circumstances of a certain or determinable natural person. This includes, for example, name, birthday, telephone number, e-mail address, and usage data, such as IP address. Furthermore, the data protection declaration serves to inform about which data is collected, stored, and processed and how."
                )}{" "}
                {domainMeta.communityName} {t("ltd. guarantees the protection")}
                {t("and security of personal data.")}
              </p>
              <p className="text-center">
                {domainMeta.communityName}{" "}
                {t(
                  ".. does not enforce the use of a real name. Instead of a name, the user can register with and make use of"
                )}
                {domainMeta.communityName} {t("under a pseudonym.")}
              </p>
            </div>
            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">
                  {" "}
                  <strong>1.</strong>{" "}
                  {t(
                    "How is my data collected, processed and used upon download of the"
                  )}{" "}
                  {domainMeta.communityName} app?
                </strong>
              </p>
            </div>
            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">
                  {" "}
                  <strong>1.1</strong> {t("Download of the")}{" "}
                  {domainMeta.communityName} app
                </strong>
              </p>

              <p className="text-center">
                {t("Upon download of the")} {domainMeta.communityName}{" "}
                {t(
                  "app, the necessary information is transmitted to the respective app store operator. This is for example, but without limitation and subject to the app store, the email address or the customer number of the app store user for the respective app store, the time of download and an individual device ID. However,"
                )}{" "}
                {domainMeta.communityName}{" "}
                {t(
                  ".. has no influence on any data collection by the app store and is not responsible or liable in such way. The corresponding privacy policy / settings of the respective app store operator are applicable and it is recommended that you check those accordingly."
                )}
              </p>
              <p className="text-center">
                {domainMeta.communityName}{" "}
                {t(
                  ".. processes the data made available by the respective app store operator solely to the extent necessary for the download of the"
                )}{" "}
                {domainMeta.communityName} app.
              </p>
            </div>
            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">
                  {" "}
                  <strong>1.2</strong> {t("Installation and permissions")}
                </strong>
              </p>

              <p className="text-center">
                {domainMeta.communityName}{" "}
                {t(
                  "requires various access capabilities and information for the technical functionality of the app."
                )}{" "}
                {domainMeta.communityName}{" "}
                {t(
                  "app and for the provision of the services offered through the app."
                )}{" "}
                {domainMeta.communityName} app.
              </p>
              <p className="text-center">
                {t(
                  "Depending on the operating system, your permission to us in order to access individual functions and information will be requested during the installation process. The access permissions may include, among others, location, messages as well as mobile data."
                )}
              </p>

              <p className="text-center">
                {t(
                  "These permissions can partly be manually controlled and organized by you in the settings of your device. In doing so, however, it should be considered that, without the respective approvals, the"
                )}{" "}
                {domainMeta.communityName}{" "}
                {t(
                  "app will only provide for limited usability or may be completely unusable. Depending on the app version, permissions will be requested either before, during or after installation."
                )}
              </p>
            </div>
            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">
                  {" "}
                  <strong>2</strong>{" "}
                  {t(
                    "How will my data be collected, processed and used upon visiting the"
                  )}{" "}
                  {domainMeta.communityName} domain
                  {t("or the")}
                  {domainMeta.communityName}{" "}
                  {t("app without creating a user profile?")}
                </strong>
              </p>

              <p className="text-center">
                {domainMeta.communityName}{" "}
                {t(
                  ".. uses the personal data that the user provides or that incurred in the use of"
                )}{" "}
                {domainMeta.communityName}{" "}
                {t(
                  "without creating a user profile, without separate consent solely for the implementation of the user relationship and as presented in this privacy policy."
                )}
              </p>
              <p className="text-center">
                {t("Upon visiting the")} {domainMeta.communityName}{" "}
                {t("domain")} (to a
                {t("so-called 'landing page' or 'homepage') or installing the")}{" "}
                {domainMeta.communityName}{" "}
                {t("app without the creation of a user profile,")}{" "}
                {domainMeta.communityName}{" "}
                {t(
                  ".. collects the following data transmitted by the user’s device/browser:"
                )}
              </p>

              <p className="text-center">
                {t(
                  "IP address, name of the website accessed, data, date and time of the request, transferred data volume, browser type and version, referrer URL (original URL) of the user who accessed the page."
                )}
                {domainMeta.communityName}{" "}
                {t(
                  "also collects the following data transmitted by the user’s device upon simple installation of the"
                )}{" "}
                {domainMeta.communityName}{" "}
                {t("app without registration or creation of a user profile:")}
              </p>

              <p className="text-center">
                {t(
                  "Push handle (for sending push notifications), location data (GPS location), country code, language, device name, name, and version of the operating system."
                )}
              </p>
              <p className="text-center">
                {t("These data are necessary for the use of the")}{" "}
                {domainMeta.communityName}{" "}
                {t(
                  "domain or the installation of the {domainMeta.communityName} app, and as such are necessary for statistical purposes and to optimize"
                )}
                {domainMeta.communityName} .
                {t(
                  "The data is processed and used to prevent and combat illegal acts and spam as well as to ensure the stability and integrity of the"
                )}{" "}
                {domainMeta.communityName} {t("platform.")}
              </p>
              <p className="text-center">
                {t(
                  "The location data is collected, processed, and used so that the user can make use of the so-called location-based service, which includes tailored recommendations for the respective location. For more details, please check sections 3.3 and 4.3 of this policy."
                )}
              </p>
              <p className="text-center">
                {t("Furthermore,")} {domainMeta.communityName}{" "}
                {t("uses cookie")} {t("(see item 4.1), analytics services and tracking providers (see item 4.2).")}
              </p>
            </div>

            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">
                  {" "}
                  <strong>3</strong> {t( "How is my data collected, processed and used after creating a user profile and upon use of the")} {" "}
                  {domainMeta.communityName} {t("Dating app and")} {" "}
                  {domainMeta.communityName} {t("domain services?")}
                </strong>
              </p>

              <p className="text-center">
                {domainMeta.communityName} {t("..uses the personal data that the user communicates or that becomes available when using")} {" "}
                {domainMeta.communityName} {t("after creating a user profile, solely for the implementation of the user relationship and as presented in this privacy policy. For such use, your additional consent is not required.")}
              </p>
              <p className="text-center">
              {t("The creation of a user profile is necessary to be able to use")}
                {domainMeta.communityName} {t("to its full extent and you herewith agree to this. In addition, you may provide further personal data, which")}
                {domainMeta.communityName} {t("Ltd. may use in order to provide the respective service and the best user experience.")}
              </p>
            </div>
            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">
                  {" "}
                  <strong>3.1</strong> {t("Mandatory details for the creation of a user profile")}
                </strong>
              </p>

              <p className="text-center">
               {t("For the creation of a user profile, the following details are essential and shall be provided:")}
              </p>
              <p className="flex flex-col items-center justify-center">
                <span>{t("Gender")}</span>
                <span>{t("Age")}</span>
                <span>{t("User name")}(pseudonym)</span>
                <span>City</span>
                <span>{t("E-mail address")}</span>
                <span>{t("password")}</span>
              </p>

              <p className="text-center">
                {t(
                  "The data provided by you will be collected, processed and used for the purpose of using"
                )}{" "}
                {domainMeta.communityName}{" "}
                {t(
                  ". Without limitation these details serve for the mandatory data like personal address, authentication, age verification, profile personalization, the display of suitable user profiles. They may also be used in connection with and for advertising purposes (see item 4)."
                )}
              </p>
            </div>

            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">
                  {" "}
                  <strong>3.2</strong> {t("Voluntary data")}
                </strong>
              </p>

              <p className="text-center">
             {t( "In addition, you can provide various voluntary details in your user profile. This serves, in particular, for the")} {" "}
                {domainMeta.communityName} {t("function whereby recommendations for other user profiles according to various criteria are selected for the user and displayed within")} {domainMeta.communityName} .
             {t("Additional information increases the chance of matching and meeting suitable user profiles. These details may be provided by you optionally and can be viewed, entered, modified or deleted by you at any time in the profile under Details.")}
              </p>
              <p className="text-center">
              {t("The data provided is collected, processed, and used by us for the purpose of delivering the")} {domainMeta.communityName}{" "}
               {t("service.")}
              </p>
              <p className="text-center">
               {t("These voluntary details are visible to all users logged in within")} {domainMeta.communityName} {t(", but can be deleted or modified at any time by the owner of the user profile under the settings in the user profile.")}
              </p>
            </div>
            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">
                  {" "}
                  <strong>3.3</strong> {t("Location data")}
                </strong>
              </p>

              <p className="text-center">
                {domainMeta.communityName} {t("also includes so-called location-based services, under which the user will have access to special functions that are tailored to the respective location. Hereby, the user or, in particular, other users, who are around and nearby, can be displayed. In order to offer these functions within the")} {domainMeta.communityName} app,{" "}
                {domainMeta.communityName} {t("collects location data via the GPS of the device in use as well as location data via radio access nodes. When the location service is active, with permission, the location will be regularly transmitted to")} {" "}
                {domainMeta.communityName} {t("Ltd., where it will be processed and used.")}
              </p>
              <p className="text-center">
                {t(
                  "The user can modify this function in the operating system settings on their device at any time. This means to permit or revoke the acquisition of location data."
                )}
              </p>
            </div>
            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">
                  {" "}
                  <strong>3.4</strong> {t("IP addresses")}
                </strong>
              </p>

              <p className="text-center">
                {t(
                  "User IP addresses are recorded for security and testing purposes. This should prevent any misuse of the service. The data is processed and used to prevent and combat illegal activities and spam as well as securing the integrity and stability of the"
                )}{" "}
                {domainMeta.communityName} platform.
              </p>
            </div>
            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">
                  {" "}
                  <strong>3.5</strong> {t("Payment data")}
                </strong>
              </p>

              <p className="text-center">
                {t(
                  "Any in-app purchases or the purchase of so-called 'Flirts' will be charged by external payment providers. No payment data is collected or processed by"
                )}{" "}
                {domainMeta.communityName}{" "}
                {t(
                  "..for any of such purchases. The entry as well as the processing of the payment data is solely by the payment provider. Technical data (the transaction ID and other) is being exchanged between"
                )}{" "}
                {domainMeta.communityName}{" "}
                {t(
                  "Ltd. and the chosen payment provider in order to validate the purchase."
                )}
              </p>
            </div>
            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">
                  {" "}
                  <strong>4</strong> Which cookies, tracking providers,
                  analytical services does {domainMeta.communityName} use? How
                  are Social Media and Like buttons embedded?
                </strong>
              </p>
            </div>
            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">
                  {" "}
                  <strong>4.1</strong> {t("Cookies and cookie policy")}
                </strong>
              </p>

              <p className="text-center">
                {t("In order to be able to use the")} {domainMeta.communityName}
                {t(
                  "domain as comfortably as possible, we use cookies on the"
                )}{" "}
                {domainMeta.communityName}{" "}
                {t(
                  "domain. Cookies are small text files that are stored locally in the cache of the Internet browser (so-called session cookies) or on the hard disk of the mobile device or computer used (so-called permanent cookies). The browser settings can be selected so that cookies are rejected or a reference is made to them before cookies are set. By setting cookies, users are assigned pseudonymized IDs. Cookies do not run programs on the user's end device or even transmit viruses. Session cookies are deleted from the cache after the end of the browser session."
                )}
              </p>
              <p className="text-center">
                {domainMeta.communityName}{" "}
                {t("also uses cookies to increase the security of the")}{" "}
                {domainMeta.communityName}{" "}
                {t(
                  "domain. For example, to authenticate the user during a session, to avoid cross-site scripting or to prevent phishing and fraudulent actions such as scamming."
                )}
              </p>
              <p className="text-center">
                {t(
                  "Furthermore, tracking providers / analysis services (see also the explanations under point 4.2 Use for the use of tracking providers / analysis services) place cookies on the user's device on behalf of"
                )}{" "}
                {domainMeta.communityName}{" "}
                {t(
                  "..in order to record the usage data. This is data that can be collected while the user is e.g., moving on the pages of the"
                )}{" "}
                {domainMeta.communityName}{" "}
                {t(
                  "domain or clicks on advertising. These cookies are used to display targeted advertising, to optimize reports on advertising campaigns and to prevent identical advertising from being displayed multiple times."
                )}
              </p>
              <p className="text-center">
                {t(
                  "Users can influence the use of cookies. Most browsers have a setting option with which the storage of cookies is restricted or completely prevented."
                )}
              </p>
              <p className="text-center">
                {t(
                  "However, we would like to point out that the use and in particular the comfort of use of"
                )}{" "}
                {domainMeta.communityName}{" "}
                {t("can be restricted without the use of cookies")}.
              </p>
            </div>

            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">
                  {" "}
                  <strong>4.1</strong>{" "}
                  {t("Tracking providers, analytics services")}
                </strong>
              </p>

              <p className="text-center">
                {domainMeta.communityName}{" "}
                {t(
                  "carries out user tracking analytics in order to optimize the"
                )}{" "}
                {domainMeta.communityName} {t("services and user experience.")}{" "}
                {domainMeta.communityName}{" "}
                {t("Ltd. implements analytical methods that enable")}{" "}
                {domainMeta.communityName} {t("to analyse visits to the")}{" "}
                {domainMeta.communityName} {t("Dating domain and")}{" "}
                {domainMeta.communityName}{" "}
                {t(
                  "app. Furthermore, third-party tracking tools are integrated to analyse the range of different advertising campaigns and marketing activities. Personal data can also be transmitted under use of third-party tools."
                )}
              </p>
              <p className="text-center">
                {t(
                  "In addition to troubleshooting, the purpose of data processing is the optimization of"
                )}{" "}
                {domainMeta.communityName}{" "}
                {t(
                  "with regard to user experience. With web analysis methods,"
                )}{" "}
                {domainMeta.communityName}{" "}
                {t(
                  "..also obtains statistics on visitor numbers and their temporal browsing of popular content as well as the duration of visits per click and page. When appropriate, it can be tracked whether a user profile was created subsequent to viewing an advertisement. The data is processed and used to prevent and combat fake profiles, unlawful activities and spam as well as to ensure the security of the"
                )}{" "}
                {domainMeta.communityName}{" "}
                {t(
                  "platform. User data is transmitted to various third parties to carry out the analysis."
                )}
              </p>
            </div>

            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">
                  {" "}
                  {t(
                    "The following analysis and tracking providers are currently in use with"
                  )}{" "}
                  {domainMeta.communityName} :
                </strong>
              </p>
            </div>
            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center"> Crashlytics</strong>
              </p>

              <p className="text-center">
                {t("The")} {domainMeta.communityName}{" "}
                {t(
                  "app uses the Firebase Crashlytics analysis tool by Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland (hereinafter 'Crashlytics'). This analysis program uses IP addresses of the users for analysis purposes, which are used only anonymously. It is not possible to trace a specific person. Crashlytics delivers analysis to"
                )}{" "}
                {domainMeta.communityName} {t("..on system crashes and")}
                {t(
                  "thereby facilitates maintenance and improvement of the"
                )}{" "}
                {domainMeta.communityName} {t("app and")}{" "}
                {domainMeta.communityName}{" "}
                {t(
                  "domain. A user ID, the clicks, and the technical device data is transmitted to analyze the error."
                )}
              </p>
              <p className="text-center">
                {t(
                  "If you have voluntarily and expressly agreed to submit a crash report in the App settings or after a crash, anonymous information (device type, version of the operating system, hardware data of the mobile device, current position in the source code, time of the crash and the state of the application for Time of the crash) will be transmitted to the servers of Google and stored for evaluation. Crash reports contain no personal information. For more information about privacy, visit"
                )}
                {""}{" "}
                <a
                  href="https://firebase.google.com/support/privacy."
                  className="text-[#2563EB]"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://firebase.google.com/support/privacy.
                </a>
              </p>
            </div>

            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">
                  {" "}
                  Google Analytics
                </strong>
              </p>

              <p className="text-center">
                {domainMeta.communityName}{" "}
                {t(
                  "uses 'Google Analytics', a service by Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland (hereinafter: 'Google')."
                )}{" "}
                {domainMeta.communityName} {t("points out that for use with")}{" "}
                {domainMeta.communityName} ,
                {t(
                  "Google Analytics has been extended with the IP masking function '_gat._anonymizeIp', to guarantee the recording of anonymized IP addresses"
                )}
              </p>

              <p className="text-center py-3">
                {t(
                  "The following information on the use of Google Analytics should be noted:"
                )}
              </p>

              <p className="text-center">
                {t(
                  "Google Analytics uses cookies that are stored on the device and enable an analysis of the use of"
                )}{" "}
                {domainMeta.communityName} .{domainMeta.communityName}{" "}
                {t(
                  "Dating usage information produced by the cookie is generally transmitted to a Google server in the USA for storage. However, since IP anonymization is active, the IP addresses of all users in the EU and associated countries are shortened by Google prior to transmission to the USA. Under contract between"
                )}
                {t("The")} {domainMeta.communityName}{" "}
                {t(
                  "Ltd. and Google, Google uses the information to evaluate the use of our services, to compile reports on activities and to provide the operator with further services."
                )}
              </p>

              <p className="text-center py-3">
                {t("The user has the option to opt out:")}
              </p>
              <p className="text-center">
                {t(
                  "Google Analytics data acquisition and storage can be revoked at any time with future effect. The user also has the option to install a browser plugin issued by Google. This is available for various browser versions and can be downloaded"
                )}{" "}
                <a
                  className="text=[#2563EB]"
                  href="https://tools.google.com/dlpage/gaoptout"
                >
                  {t("here")}
                </a>
                {t(
                  ". You can learn more about data usage for advertising purposes, settings and opting out on the following Google webpages:"
                )}
              </p>
              <div className="flex items-center justify-center flex-col gap-4">
                <a className="text-[#2563EB]" href="">
                  {t(
                    "('Google’s data usage when you use our partners’ websites and apps')"
                  )}
                </a>
                <a className="text-[#2563EB]" href="">
                  {t("('Data usage for advertising purposes')")}
                </a>
                <a className="text-[#2563EB]" href="">
                  {t("‘Data usage for advertising purposes’")}{" "}
                  <span className="text-black">{t("and")}</span>{" "}
                </a>
                <a className="text-[#2563EB]" href="">
                  {t("('Determine what advertising Google places')")}
                </a>
              </div>
            </div>

            <div className="flex justify-center items-center flex-col mt-4">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center"> Adjust</strong>
              </p>

              <p className="text-center">
                {domainMeta.communityName}{" "}
                {t(
                  "uses the app 'adjust', a product of the company adjust GmbH, Saarbrücker Str. 37a in 10405 Berlin, Germany, as a usage analysis tool. When the user installs the"
                )}
                {domainMeta.communityName}{" "}
                {t(
                  "app, adjust stores installation and event data from the iOS or Android app. Using this, we can understand how our users interact with the"
                )}{" "}
                {domainMeta.communityName}{" "}
                {t(
                  "app. Moreover, we can thereby analyze and improve our mobile advertising campaigns. adjust uses the IDFA or advertiser ID as well as your anonymized ID and MAC address. The data is unilaterally made anonymous. This means that it is not possible to identify you or your mobile device. If you don’t want adjust tracking, please select"
                )}{" "}
                <a
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                  className="text-[#2563EB]"
                >
                  here.
                </a>
              </p>
            </div>
            <div className="flex justify-center items-center flex-col mt-4">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">Rapidmail</strong>
              </p>

              <p className="text-center">
                {t(
                  "Send out of newsletters and other emails is carried out by our email marketing service provider rapidmail GmbH, Augustinerplatz 2, 79098 Freiburg i.Br., Germany. You can see the privacy policy of the email marketing service provider"
                )}{" "}
                <a
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                  className="text-[#2563EB]"
                >
                  here.
                </a>
                {t(
                  "The email marketing service is used on the basis of our legitimate interests in accordance with Article 6, paragraph 1, point f of the GDPR and a processor contract in accordance with Article 28, paragraph 3 of the GDPR."
                )}
              </p>
            </div>
            <div className="flex justify-center items-center flex-col mt-4">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">
                  Google Firebase
                </strong>
              </p>

              <p className="text-center">
                {t(
                  "Furthermore, we use the service 'Google Firebase' for the analysis and characterization of our user groups and the delivery of push notifications. You can find Firebase’s privacy policy here (insert link) as well as Google’s privacy policy"
                )}{" "}
                <a
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                  className="text-[#2563EB]"
                >
                  here.
                </a>
              </p>
            </div>
            <div className="flex justify-center items-center flex-col mt-4">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">Fyber</strong>
              </p>

              <p className="text-center">
                {t(
                  "For app monetization, we use Fyber, a Fyber GmbH service from Berlin. The company Fyber also sets cookies. We do not use Fyber via JavaScript integration. Your IP address is not made accessible. Fyber only receives pseudonymized data from us for billing purposes. The data cannot be used by Fyber to trace a specific person. Further details on data protection can be found"
                )}{" "}
                <a
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                  className="text-[#2563EB]"
                >
                  here.
                </a>
              </p>
            </div>
            <div className="flex justify-center items-center flex-col mt-4">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">
                  Google-Tag-Manager
                </strong>
              </p>

              <p className="text-center">
                {t(
                  "We use Google Tag Manager. Google Tag Manager is a solution with which marketers can manage website tags via an interface. The Tag Manager tool itself is a domain without cookies and the tool does not collect any personal data. The tool triggers other tags, which for their part may collect data. Google Tag Manager does not access this data. If disabled at the domain or cookie level, this remains the case for all tracking tags that were implemented with Google Tag Manager."
                )}
              </p>
            </div>
            <div className="flex justify-center items-center flex-col mt-4">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">
                  Google Adwords
                </strong>
              </p>

              <p className="text-center">
                {t(
                  "We use the online advertising program 'Google AdWords' and conversion tracking within the framework of Google AdWords. Google Conversion Tracking is an analytical service by Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland ('Google'). When you click on an advertisement placed by Google, a cookie for the conversion tracking will be placed on your computer. The cookies have limited validity, contain no personal data, and do not enable a personal identification. If you visit certain internet pages on our website and the cookie has not yet expired, we and Google can recognize that you clicked on the advertisement and were redirected to this site. Every Google AdWords customer receives a different cookie. It is therefore not possible for cookies to be tracked over the websites of Google AdWords customers. The information obtained with the help of the conversion cookie serves for the creation of conversion statistics for AdWords customers, who have selected conversion tracking. Hereby, the customers learn the total number of users that have clicked on their advert and have been redirected to a page with a conversion tracking tag. They receive, however, no information that can be used to personally identify users. If you do not want to participate in tracking, you can opt out by blocking the installation of cookies through a corresponding setting in your browser software (deactivation option). Upon opting out you will not be recorded in the conversion tracking statistics. You can find further information as well as Google’s privacy policy"
                )}{" "}
                <a
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                  className="text-[#2563EB]"
                >
                  here.
                </a>
              </p>
            </div>

            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">
                  {" "}
                  Google ReCaptcha
                </strong>
              </p>

              <p className="text-center">
                {t(
                  "We integrate the function for the recognition of bots ('ReCaptcha'), e.g. for entries in online forms, offered by Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland. You have the right to"
                )}{" "}
                <a href="#" className="text-[#2563EB]">
                  {t("opt out.")}
                </a>
              </p>
            </div>
            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">
                  {" "}
                  {t("Facebook Pixel and SDK")}
                </strong>
              </p>

              <p className="text-center">
                {t(
                  "We integrate the function for the recognition of bots ('ReCaptcha'), e.g. for entries in online forms, offered by Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland. You have the right to "
                )}
                <a
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                  className="text-[#2563EB]"
                >
                  {t("opt out.")}
                </a>
              </p>
              <p className="text-center">
                {t("We use the 'Facebook Pixel' on our websites")}{" "}
                <a
                  // href={`${domainMeta.internet}`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-[#2563EB]"
                >
                  https://www. {domainMeta.communityName} .love {""}
                </a>
                {""}
                and
                {""} {""}
                <a
                  // href={`${domainMeta.internet}`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-[#2563EB]"
                >
                  {""}
                  https:// {domainMeta.communityName} .blog/
                  {""} {""} {""}
                </a>
                {""}
                Chat Dating.love and https:// {domainMeta.communityName} .blog/
                {t("and in our")}
                {domainMeta.communityName}{" "}
                {t(
                  "App the 'Software Developer KIT' (SDK) of the social network Facebook, 1601 South California Avenue, Palo Alto, CA 94304, United States. These counting pixels are integrated on our pages and in the"
                )}{" "}
                {domainMeta.communityName}{" "}
                {t(
                  "When visiting our websites and using our app, a direct connection between your browser or mobile device and the Facebook server is being established."
                )}
              </p>
              <p className="text-center">
                {t(
                  "Facebook gets the information from your browser that our website has received from your device. If you are a Facebook user, Facebook may assign your visit to our Sites and the use of our App to your account. We point out that we are not aware of the content of the transmitted data and their use by Facebook. We can only choose which category of Facebook users (such as age, interests) our ad should display."
                )}
              </p>
              <p className="text-center">
                {t(
                  "By calling the pixel from your browser, Facebook can also tell if a Facebook ad was successful. In this way, we record the effectiveness of Facebook advertisements for statistical and market research purposes."
                )}
              </p>
              <p className="text-center">
                {t(
                  "We use the data obtained from the Facebook pixel in the Facebook analysis tool 'Facebook Analytics' to optimize our websites and our app."
                )}
              </p>

              <p className="text-center">
                {t("For more information, see the Facebook Privacy Policy at")}{" "}
                <a
                  href="https://www.facebook.com/about/privacy/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-[#2563EB]"
                >
                  https://www.facebook.com/about/privacy/. {""}
                </a>{" "}
                {t(
                  "Please click on the following link if you do not want to collect data via Facebook Pixel:"
                )}{" "}
                {""}
                <a
                  href="https://www.facebook.com/settings?tab=ads#_=_"
                  target="_blank"
                  rel="noreferrer"
                  className="text-[#2563EB]"
                >
                  {""}
                  {t("Opt out of Facebook Pixel Tracking here")}
                </a>
              </p>
            </div>

            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">
                  {" "}
                  <strong>4.2</strong> {t("Social media links")}
                </strong>
              </p>

              <p className="text-center">
                {t("Links to")} {domainMeta.communityName}{" "}
                {t(
                  "..accounts with the social networks Instagram and Facebook are incorporated on the"
                )}{" "}
                {domainMeta.communityName}{" "}
                {t(
                  "domain. After clicking on the integrated graphic, the user will be redirected to the respective provider of Instagram or Facebook. This means that only then will user information be transmitted to the respective provider."
                )}
              </p>
              <p className="text-center">
                {t(
                  "If the user is logged in to their user profile on the respective social network, after activation of the button, an association with the"
                )}{" "}
                {domainMeta.communityName} {t("visit is made.")}
              </p>
              <p className="text-center">
                {t("If the user does not want data to be collected via the")}{" "}
                {domainMeta.communityName}{" "}
                {t(
                  "Dating domain by the social networks, they should log out of these before visiting the"
                )}{" "}
                {domainMeta.communityName}{" "}
                {t(
                  "domain. However, every time the corresponding button is activated through a click, a cookie or cookies with an identifier are set by"
                )}{" "}
                {domainMeta.communityName}{" "}
                {t(
                  "Dating. Data could be collected and a profile created through the use of this function, which could, under certain circumstances, be traced back to an individual person. If the user does not want this, they can deactivate the link within the"
                )}
                {domainMeta.communityName}{" "}
                {t(
                  "domain with a click. The user can also set up their browser in such a way so as to generally exclude the use of cookies. We point out, however, that, in this case, functionality of"
                )}{" "}
                {domainMeta.communityName} {t("could be limited.")}
              </p>
              <p className="text-center">
                {t(
                  "Information on the handling of personal data when using these websites can be taken from the respective provider’s privacy policy."
                )}
              </p>
            </div>

            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center"> Facebook</strong>
              </p>

              <p className="text-center">
                {t(
                  "Facebook’s privacy policy (operated by Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA) can be found"
                )}
                <a
                  href="https://facebook.com/about/privacy/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-[#2563EB]"
                >
                  {""}
                  here
                </a>
              </p>
            </div>
            <div className="flex justify-center items-center flex-col">
              <p className="flex items-center justify-center">
                <strong className="text-base text-center">Instagram</strong>
              </p>

              <p className="text-center">
                {t(
                  "Instagram’s privacy policy (operated by Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA) can be found"
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Privacy;
