import { useEffect, useState } from "react";
import {useDispatch} from 'react-redux'
import {setLoading} from 'src/redux/index'
import { IMAGE_BASE_URL } from 'src/utils/constants'
import { getAuthToken } from 'src/apollo'
const useLoading = (value) => {
  const [isloading,setIsLoading] = useState(false)
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(setLoading(value))
 },[])

  return [isloading,setIsLoading]
};

const getUserImage = (image) => {
  if(!image) return 'https://cdni.iconscout.com/illustration/premium/thumb/male-user-image-8110251-6515860.png?f=webp'
  return `${IMAGE_BASE_URL}${image}`
}

const setUserPhotos =  (userPhotos) => {
  return new Promise((resolve,reject)=>{
    let toReturnData = [ ... userPhotos]
    toReturnData = toReturnData.map( async (photo)=>{
      if(photo.image == '') {
        let blurredImage = await getUserPicture(photo.encryptedUrl)
        photo.blurredImage = blurredImage
        console.log(blurredImage,"blurredImage")
      }
      return photo
    }) 
    Promise.all(toReturnData).then((values) => {
      resolve(values)
    });
  })
}

const getUserPicture = (message_url) => {
  let url = 'https://flirttool.com/rest/get-user-image'
  // let url = 'http://localhost:8080/rest/get-user-image'

  return new Promise((resolve,reject)=>{
    fetch(url,{
       method: 'POST',
       headers:{
          'Content-Type': 'application/json',
          token:getAuthToken()
       },
       body:JSON.stringify({image:message_url})
    })
      // Retrieve its body as ReadableStream
      .then((response) => {
        const reader = response.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  controller.close();
                  return;
                }
                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                return pump();
              });
            }
          }
        })
      })
      // Create a new response out of the stream
      .then((stream) => new Response(stream))
      // Create an object URL for the response
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob))
      // Update image
      .then((url)=>{
          resolve(url)
      })
      .catch((err) => console.error(err));
    })
}

export { useLoading ,getUserImage ,setUserPhotos ,getUserPicture };
