import { useState, useEffect } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useQuery, useSubscription } from "@apollo/client";
import { setUserDiscountTimer } from "src/redux/user/userActions";
import {SETUP_COMPLETE_VALUE} from 'src/utils/constants'
import "src/assets/css/bootstrap.css";
import "src/assets/css/user.scss";
import "src/assets/css/zusatz.scss";
import "src/assets/css/loader.scss";
import usePageTracking from "src/hooks/usePageTracking";

import AppHeader from "src/components/AppHeader";
import AppFooter from "src/components/AppFooter";

import Home from "src/views/App/Home";
import Chat from "src/views/App/Chat";
import Profile from "src/views/App/Profile";
import Liked from "src/views/App/Liked";
import DisLiked from "src/views/App/DisLiked";
import Packages from "src/views/App/Packages";
import ProfileSetup from "src/views/App/ProfileSetup";

import ProfileSettings from "src/views/App/ProfileSettings";
import SupportForm from "src/views/App/SupportForm";
import PurchaseHistory from "src/views/App/PurchaseHistory";
import Flirtons from "src/views/App/Flirtons";
import FlirtonPopup from "src/components/Main/Sub/FlirtonPopup";
import useLocation from "src/utils/useLocation";

import EventEmitter from "src/event";
import MobileAppMenu from "src/templates/Basic/components/Layout/MobileAppMenu";
import {
  GET_USER_DISCOUNT,
  MESSAGE_LISTEN,
  NOTIFICATION_ADDED,
  UPDATE_USER_LOCATION,
} from "src/queries";
import { apolloClient } from "src/index";
import { toast } from "react-toastify";

function App() {
  usePageTracking();
  const [notifications, setNotifications] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  // const { locationSet } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);

  console.log(userData, "USERDATA");

  console.log(userData);
  // const userLocation = useSelector((state) => state.user.location);

  const { data } = useQuery(GET_USER_DISCOUNT);
  useEffect(() => {
    if (data) {
      dispatch(setUserDiscountTimer(data));
    }
  }, [data, dispatch]);

  useSubscription(MESSAGE_LISTEN, {
    onData: (data) => {
      console.log("MESSAGE_LISTEN_GLOBAL");
      EventEmitter.emit("MESSAGE_LISTEN", data);
    },
    onError: (error) => {
      alert("error");
      console.log(error);
    },
    onComplete: () => {
      alert("complete");
    },
  });

  useSubscription(NOTIFICATION_ADDED, {
    onData: ({ data }) => {
      console.log("NOTIFICATION_ADDED", data);
      alert("notification added");
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        data.notificationAdded,
      ]);
      EventEmitter.emit("NOTIFICATION_ADDED", data.notificationAdded);
    },
    onError: (error) => {
      console.error("Notification subscription error:", error);
    },
  });

  // const sendLocationToServer = async () => {
  //   try {
  //     await apolloClient.mutate({
  //       mutation: UPDATE_USER_LOCATION,
  //       variables: {
  //         input: {
  //           lat: String(userLocation.lat),
  //           lng: String(userLocation.lng),
  //           location: JSON.stringify(userLocation),
  //         },
  //       },
  //     });
  //   } catch (error) {
  //     console.error("Error updating user location:", error);
  //   }
  // };

  // useEffect(() => {
  //   if (locationSet) {
  //     sendLocationToServer();
  //   }
  // }, [locationSet]);
  useEffect(() => {
    setTimeout(() => {
      if (!userData.token) {
        navigate("/");
      } else if (userData?.userData?.profileSetupStep < SETUP_COMPLETE_VALUE) {
        navigate("/profile-setup");
      } else {
        // navigate("/find-matches");
      }
    }, 0);
  }, [userData.token, userData?.userData?.profileSetupStep]);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  if (!userData.token) return null;

  return (
    <div>
      <MobileAppMenu toggleSidebar={toggleSidebar} isOpen={isOpen} />
      <AppHeader userData={userData} toggleSidebar={toggleSidebar}  />
      <FlirtonPopup />
      {userData?.userData?.profileSetupStep < SETUP_COMPLETE_VALUE ? (
        <Routes>
          <Route exact path="/profile-setup" element={<ProfileSetup />} />
          <Route path="*" element={<Navigate to="/profile-setup" />} />
        </Routes>
      ) : (
        <Routes>
          <Route exact path="/find-matches" element={<Home />} />
          <Route exact path="/chat" element={<Chat />} />
          <Route exact path="/@/:username" element={<Profile />} />
          <Route exact path="/profile-settings" element={<ProfileSettings />} />
          <Route exact path="/liked" element={<Liked />} />
          <Route exact path="/disliked" element={<DisLiked />} />
          <Route exact path="/flirts" element={<Packages />} />
          <Route exact path="/support" element={<SupportForm />} />
          <Route exact path="/purchase-history" element={<PurchaseHistory />} />
          <Route exact path="/profile-setup" element={<ProfileSetup />} />
          <Route exact path="/flirtons" element={<Flirtons />} />
        </Routes>
      )}
      <AppFooter />
    </div>
  );
}

export default App;
