import { activeTheme } from "src/themes";
import { lazy, Suspense } from "react";
import { PageSkeleton } from "src/components/common/Skeletons";
import { GET_ALL_TRANSACTIONS } from "src/queries";
import { toast } from "react-toastify";
import { useQuery } from "@apollo/client";

const Template = lazy(() =>
  import(`src/templates/${activeTheme}/views/PurchaseHistory.jsx`)
);

const PurchaseHistory = () => {
  let transactionData;
  const {
    data: transaction,
    loading: transLoading,
    error: transError,
  } = useQuery(GET_ALL_TRANSACTIONS);

  if (transaction) {
    transactionData = transaction.transactions;
  }

  if (transError) {
    toast("Something went wrong");
  }

  return (
    <Suspense fallback={<PageSkeleton />}>
      <Template transactionData={transactionData} transLoading={transLoading} />
    </Suspense>
  );
};

export default PurchaseHistory;
