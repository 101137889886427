import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect } from "react";
import { themeStyle } from "src/themes";
import card from "src/assets/img/card.png";
import paypal from "src/assets/img/paypal.png";
import Bitcoin from "src/assets/img/bitcoin.png";
import {useSelector,useDispatch} from 'react-redux'
import {setFlirtonPopup} from 'src/redux/index'
import { FaTimes } from "react-icons/fa";
import { GET_FLIRTONS ,CHECKOUT_STRIPE ,CREATE_COINBASE_CHARGE} from "src/queries";
import { useQuery ,useMutation} from "@apollo/client";
import { PackageSkeleton } from "src/components/common/Skeletons";



function FlirtonPopup() {
  const appData = useSelector((state) => state.app);
  const dispatch = useDispatch()
  const { loading, error, data } = useQuery(GET_FLIRTONS,{
    fetchPolicy:'no-cache'
  });
  const [createStripeCheckout, stripeMutation] = useMutation(CHECKOUT_STRIPE);
  const [createCoinbaseCharge, coinbaseMutation] = useMutation(CREATE_COINBASE_CHARGE);

  

  const cardPaymentHandler = useCallback(async (packageData) => {
    let input = {
      package: packageData.packageId,
      discount: null,
      coupon: null,
      currency: "usd",
    };
    await createStripeCheckout({ variables: { input } });
  },[])

  const coinbasePaymentHandler = async (packageData) => {
    await createCoinbaseCharge({
      variables: { package: String(packageData.id) },
    });
  };
  
  useEffect(() => {
    if (stripeMutation.data) {
      window.open(stripeMutation.data.createStripeCheckout);
    }
  }, [stripeMutation.data]);

  useEffect(() => {
    if (coinbaseMutation.data) {
      window.open(coinbaseMutation.data.CoinbaseCharge.charge);
    }
  }, [coinbaseMutation.data]);
  const closePop = useCallback(() => {
    dispatch(setFlirtonPopup(false))
  },[])

  if(!appData) return
  if(!appData.showFlirtonPop) return
  if(!data?.all_flirtons) return
  return (
    <AnimatePresence>
      <div className="fixed z-50 top-0 left-0 right-0 bottom-0 backdrop-brightness-50">
        <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
          <motion.div
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ y: -100, opacity: 0 }}
            
          >
            <Flirton 
              closePop={closePop} 
              loading={loading} 
              data={data} 
              cardPaymentHandler={cardPaymentHandler} 
              coinbasePaymentHandler={coinbasePaymentHandler}
              loadingStripe={stripeMutation.loading}
              loadingCoinbase={coinbaseMutation.loading}
            />
          </motion.div>
        </div>
      </div>
    </AnimatePresence>
  );
}


const Flirton = (props) => {
  const { closePop,loading,data,cardPaymentHandler,coinbasePaymentHandler ,loadingStripe ,loadingCoinbase } = props
  let flirtOn = data.all_flirtons[0]
  return (
    <div className="bg-white md:w-[70vh] w-screen py-8 px-6 rounded-lg">
      <div className="w-full text-gray-600">
        <div className="text-center">
          <div className="flex justify-end">
            <button>
              <FaTimes onClick={() => closePop()} />
            </button>
          </div>
          <h1 className="text-3xl font-bold">{flirtOn.packageName}</h1>
        </div>
        <div className="text-center text-xl">
          <span>{flirtOn.packageDesc}</span>
        </div>
        {loading && <PackageSkeleton/>}

        {!loading && 
          <div className="grid grid-cols-2 justify-items-center mt-6">
            
            <div className="w-full h-full">
              <motion.button
                onClick={() => cardPaymentHandler(data.all_flirtons[0])}
                style={{ backgroundColor: themeStyle.lightBackground }}
                whileHover={{ backgroundColor: themeStyle.lightBackground2 }}
                whileTap={{ backgroundColor: themeStyle.background }}
                className="w-full h-full transition duration-300"
              >
                <span className="flex justify-center items-center text-lg font-bold">
                  <img src={card} alt="card" width={40} />
                  {!loadingStripe ? "CARD" : "Loading..."}
                </span>
              </motion.button>
            </div>
            <div className="w-full h-full">
              <motion.button
                onClick={() => coinbasePaymentHandler(data.all_flirtons[0])}
                style={{ backgroundColor: "white" }}
                whileHover={{ backgroundColor: themeStyle.lightBackground2 }}
                whileTap={{ backgroundColor: themeStyle.background }}
                className="w-full h-full transition duration-300 border"
              >
                <span className="flex justify-center items-center text-lg font-bold">
                  <img src={Bitcoin} alt="bitcoin" width={40} />
                  {!loadingCoinbase ? "Coinbase" : "Loading..."}
                </span>
              </motion.button>
            </div>
          </div>
        }
        <div className="mt-2">
          <label htmlFor="coupon" className="text-sm font-bold">
            Enter your coupon code here
          </label>
          <div className="pt-3 pb-2.5">
            <input
              type="text"
              id="coupon"
              name="coupon"
              className="border border-gray-700 h-12 w-full p-3 rounded-lg"
              placeholder="Enter your coupon code here..."
            />
          </div>
        </div>
        <div>
          <motion.button
            className="border border-gray-800 h-12 w-full rounded-lg font-medium text-lg transition duration-300"
            style={{ backgroundColor: themeStyle.lightBackground }}
            whileHover={{ backgroundColor: themeStyle.lightBackground2 }}
            whileTap={{ backgroundColor: themeStyle.background }}
            onClick={() => alert("Invalid coupon")}
          >
            Verify
          </motion.button>
        </div>
      </div>
    </div>
  )
}
export default FlirtonPopup;
