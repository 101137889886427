import { Link } from "react-router-dom";
import { themeStyle } from "src/themes";
import { motion } from "framer-motion";

const PaymentMessage = ({ icon, message, desc }) => {
  return (
    <div className="flex justify-center py-28 px-4 text-center">
      <div>
        <div
          className="inline-block p-6 rounded-full"
          style={{
            borderWidth: "18px",
            borderColor: !message.includes("Failed")
              ? themeStyle.background
              : "red",
          }}
        >
          <div
            className="text-8xl"
            style={{
              color: !message.includes("Failed")
                ? themeStyle.background
                : "red",
            }}
          >
            {icon}
          </div>
        </div>
        <div className="py-3 mt-4">
          <h4 className="font-semibold text-xl">{message}</h4>
        </div>
        <div className="py-1">
          <p>{desc}</p>
        </div>
        <div className="mt-2">
          <Link to="/">
            <motion.button
              type="button"
              className="uppercase px-6 py-2.5 font-semibold rounded-lg transition duration-300"
              style={{
                borderColor: themeStyle.background,
                borderWidth: "1px",
                color: themeStyle.background,
              }}
              whileHover={{
                backgroundColor: themeStyle.background,
                color: "white",
              }}
            >
              Find Matches
            </motion.button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PaymentMessage;
