import { activeTheme } from "src/themes";
import { lazy, Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { PageSkeleton } from "src/components/common/Skeletons";
import useLocation from "src/utils/useLocation";
import { UPDATE_USER_LOCATION } from "src/queries";
import { apolloClient } from "src/index";

const Template = lazy(() =>
  import(`src/templates/${activeTheme}/views/Home.jsx`)
);

function Home() {
  const { locationSet } = useLocation();
  const userLocation = useSelector((state) => state.user.location);

  console.log(userLocation);

  const userData = useSelector((state) => state.user);
  const sendLocationToServer = async () => {
    try {
      await apolloClient.mutate({
        mutation: UPDATE_USER_LOCATION,
        variables: {
          input: {
            lat: String(userLocation.lat),
            lng: String(userLocation.lng),
            location: JSON.stringify(userLocation),
          },
        },
      });
    } catch (error) {
      console.error("Error updating user location:", error);
    }
  };

  useEffect(() => {
    if (locationSet) {
      sendLocationToServer();
    }
  }, [locationSet]);

  return (
    <>
      <Suspense fallback={<PageSkeleton />}>
        <Template userData={userData} />
      </Suspense>
    </>
  );
}

export default Home;
