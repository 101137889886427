import { activeTheme } from "src/themes";
import { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { PageSkeleton } from "src/components/common/Skeletons";
const Template = lazy(() => import(`src/templates/${activeTheme}/views/Landing.jsx`));

function Index() {
  const appData = useSelector(state => state.app)

  return (
    <Suspense fallback={<PageSkeleton />}>
      <Template appData={appData}/>
    </Suspense>
  );
}

export default Index;
