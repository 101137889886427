import { SET_WELCOME_MESSAGE_MODAL } from "./messageTypes";

const initialState = {
  showMessageModal: false,
};

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WELCOME_MESSAGE_MODAL:
      return {
        ...state,
        showMessageModal: action.data,
      };
    default:
      return state;
  }
};

export default messageReducer;
