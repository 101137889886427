
import { useCallback, useEffect, useState } from "react";
import { useStore } from "react-redux";
import { apolloClient } from "src/index";
import { PROFILE_DETAIL_SETUP_TYPES ,LOOKS_PERSONALITY_TYPES ,LIFE_STYLE_FAVOURITES_LS} from "src/queries";

const getProfileSetupTypes = async () =>{ 
  try{ 
    const { data, errors } = await apolloClient.query({
      query: PROFILE_DETAIL_SETUP_TYPES,
      fetchPolicy:"no-cache",
    });
    return {
      status:true,
      data:data.profileDetailSetupTypes
    }
  }catch(e) { 
    return {
      status:false
    }
  }
}

const getLooksPersonalityTypes = async () =>{ 
  try{ 
    
    const { data, errors } = await apolloClient.query({
      query: LOOKS_PERSONALITY_TYPES,
      fetchPolicy:"no-cache",
    });
    return {
      status:true,
      data:data.looksAndPersonalitySetupTypes
    }
  }catch(e) { 
    return {
      status:false
    }
  }
}

const getLifestyleTypes = async () =>{ 
  try{ 
   
    const { data, errors } = await apolloClient.query({
      query: LIFE_STYLE_FAVOURITES_LS,
      fetchPolicy:"no-cache",
    });
    return {
      status:true,
      data:data.lifeStyleAndFavouritesSetupTypes
    }
  }catch(e) { 
    return {
      status:false
    }
  }
}

function useProfileTypes() { 

  const [profileSetupTypes,setProfileSetupTypes] = useState(null)
  const [setupLoading,setSetupLoading] = useState(false)
  const [looksPersonalityTypes,setLooksPersonalityTypes] = useState(null)
  const [lifestyleTypes,setLifestyleTypes] = useState(null)


  useEffect(()=>{
    getAllTypes()
  },[])

  const getAllTypes = async () => {
    setSetupLoading(true)
    let profileSetupTypes = await getProfileSetupTypes()
    setProfileSetupTypes(profileSetupTypes.data)
    let looksTypes = await getLooksPersonalityTypes()
    setLooksPersonalityTypes(looksTypes.data)
    let lifestyleTypes = await getLifestyleTypes()
    setLifestyleTypes(lifestyleTypes.data)
    setSetupLoading(false)
  }

  

  return [
    profileSetupTypes,
    looksPersonalityTypes,
    lifestyleTypes,
    setupLoading,
  ]
}

export default useProfileTypes