import heropng from "src/assets/img/1.png";
import { FaFacebook } from "react-icons/fa6";
import { FcGoogle } from "react-icons/fc";
import GPlayBtn from "src/assets/img/googleStoreBtn.png";
import appleBtn from "src/assets/img/appstoreBtn.png";
import { IoMdClose } from "react-icons/io";
import LanguageSelector from "src/templates/Basic/components/forms/LanguageSelector";
import { useSelector } from "react-redux";
import Login from "src/views/Web/Login";
import Register from "src/views/Web/Register";
import { useTranslation } from "react-i18next";
import { getDomainMeta } from "src/themes";

function RegisterPage() {
  const { t } = useTranslation();
  const domainMeta = getDomainMeta();
  const appData = useSelector((state) => state.app);
  const login = () => {
    document.getElementById("signupForm").style.left = "110%";
    document.getElementById("LoginForm").style.left = "0%";
  };

  const signup = () => {
    document.getElementById("phoneSignup").style.display = "flex";
    document.getElementById("phoneLogin").style.display = "none";
  };

  const closePhoneForm = () => {
    document.getElementById("phoneSignup").style.display = "none";
    document.getElementById("phoneLogin").style.display = "none";
  };

  return (
    <>
      <div className="max-w-[1000px] mx-auto  px-4 md:px-0">
        <div className="flex justify-between items-center relative py-2 px-0">
          <div>
            <a
              href="/"
              aria-label="Company"
              title="Company"
              className="indivne-flex items-center"
            >
              <img
                src={`https://mazutech.online/${appData.communityMeta?.logo}`}
                alt=""
                className="object-cover w-32"
              />
            </a>
          </div>
          <div className="flex items-center gap-2 justify-end">
            <div className="flex items-center">
              <LanguageSelector />
            </div>
          </div>
        </div>
      </div>
      <div className="flex relative overflow-hidden">
        <img
          className="absolute phone:h-full lg:h-auto phone:max-w-none sm:max-w-[100%] phone:left-[-90%] bigPhone:left-[-30%] sm:left-0"
          src={heropng}
        />

        <div className="flex max-w-[1000px] h-max mx-auto sm:mt-[80px] phone:mt-[40px] z-10">
          <div className="flex flex-col p-8">
            {/* <img src={logo} className="sm:w-[300px] phone:w-[100px]" /> */}
            <h1 className="flex text-center md:hidden sm:text-7xl phone:text-5xl font-semibold sm:mt-24 phone:mt-8">
              {t("welcomeTo")} {`${domainMeta.main_heading}`}
            </h1>
            <h1 className="hidden md:flex sm:text-7xl phone:text-5xl font-semibold sm:mt-24 phone:mt-8">
              {t("welcomeTo")} {`${domainMeta.main_heading}`}
            </h1>
            <span className="my-8 text-center md:hidden text-xl font-bold">
              {t("Already registered? Login")}
            </span>
            <span className="my-8 hidden md:flex text-xl font-bold">
              {t("Already registered? Login")}
            </span>
            <div className="flex items-center">
              <button
                onClick={login}
                className="phone:hidden lg:flex bg-[#FF005C] text-white px-8 py-2 rounded-full"
              >
                {t("Login")}
              </button>
              <button
                onClick={signup}
                className="phone:flex lg:hidden bg-[#FF005C] text-white px-8 py-2 rounded-full"
              >
                {t("Signup")}
              </button>
              <span className="mx-4">Or</span>
              <span className="flex items-center font-bold sm:text-xl phone:text-sm">
                Continue with &nbsp;&nbsp;
                <FaFacebook
                  size={24}
                  color="#1877F2"
                  className=" cursor-pointer"
                />
                &nbsp; &nbsp; &nbsp;
                <FcGoogle size={25} className=" cursor-pointer" />
              </span>
            </div>

            <div className="flex items-center my-8 phone:flex-col sm:flex-row">
              <img src={GPlayBtn} className="w-[200px]" />
              <img
                src={appleBtn}
                className="w-[200px] mx-2 phone:my-2 sm:my-0"
              />
            </div>
          </div>
          <div className="phone:hidden lg:flex relative w-[600px] h-max rounded-[30px] overflow-hidden ml-12 mt-8 mb-10 justify-center ">
            <Register />
            <Login />
          </div>
          {/* phone  */}
          <div
            className="hidden fixed h-full w-full bg-[#FF005C] top-0 left-0 justify-center items-center"
            id="phoneSignup"
          >
            <span className="absolute right-4 top-4">
              <IoMdClose color="white" size={22} onClick={closePhoneForm} />
            </span>

            <Register />
          </div>
          <div
            className="hidden fixed h-full w-full bg-black top-0 left-0 justify-center items-center"
            id="phoneLogin"
          >
            <span className="absolute right-4 top-4 z-10">
              <IoMdClose color="white" size={22} onClick={closePhoneForm} />
            </span>
            <Login />
          </div>
        </div>
      </div>
    </>
  );
}
export default RegisterPage;
