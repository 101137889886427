import { activeTheme } from "src/themes";
import { lazy, Suspense } from "react";
import { PageSkeleton } from "src/components/common/Skeletons";
const Template = lazy(() => import(`src/templates/${activeTheme}/components/Main/Sub/QuickChatHeader.jsx`));

function QuickChatHeader({quickChatOpen,setQuickChatOpen,unreadMessageLength}) {
  return (
    <Suspense fallback={<PageSkeleton />}>
    <Template 
      quickChatOpen={quickChatOpen}
      setQuickChatOpen={setQuickChatOpen}
      unreadMessageLength={unreadMessageLength}
    />
  </Suspense>
  )
}

export default QuickChatHeader;
