import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { split, HttpLink } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";

import { WebSocketLink } from "@apollo/client/link/ws";
import { SubscriptionClient } from "subscriptions-transport-ws";

export const production = true;
const host = window.location.hostname;
let serverUrl = "globalchat.us";

const baseUrl = production
  ? `https://${host}/graphql`
  : `https://${serverUrl}/graphql`;  
const wsUrl = production
  ? `wss://${host}/subscriptions`
  : `wss://${serverUrl}/subscriptions`;
// const baseUrl = "http://localhost:8080/graphql";

const getAuthToken = () => {
  let reduxData = localStorage.getItem("persist:root");
  if (!reduxData) return null;
  reduxData = JSON.parse(reduxData);
  let userData = reduxData.user;
  if (!userData) return null;
  userData = JSON.parse(userData);
  return `Bearer ${userData.token}`;
};

const wsLink2 = new WebSocketLink(
  new SubscriptionClient(wsUrl, {
    reconnect: true,
    connectionParams: {
      Authorization: getAuthToken(),
    },
  })
);
// wsLink2.onConnected(() => console.log("websocket connected!!"))
// wsLink2.onDisconnected(() => console.log("websocket disconnected!!"))
// wsLink2.onReconnected(() => console.log("websocket reconnected!!"))
const wsLink = new GraphQLWsLink(
  createClient({
    // url: 'wss://souldates.de/subscriptions',
    url: "ws://localhost:8080/subscriptions",
    connectionParams: () => {
      return { Authorization: getAuthToken() };
    },
  })
);
const httpLink = new HttpLink({
  uri: baseUrl,
  headers: {
    authorization: getAuthToken(),
  },
});
const setAuthorizationLink = setContext((request, previousContext) => ({
  headers: {
    ...previousContext.headers,
    authorization: getAuthToken(),
  },
}));
const uploadLink = createUploadLink({ uri: baseUrl });
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink2,
  setAuthorizationLink.concat(uploadLink)
);

export { splitLink, baseUrl, getAuthToken };
