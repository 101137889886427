import SoundMessage from "src/assets/sounds/new_message.mp3";

export const soundMessage = new Audio(SoundMessage);

export const playSound = (soundType) => {
  if (soundType == "new_message") {
    playAudio(soundMessage);
  }
};
const playAudio = (audio, repeat = true) => {
  if (audio.paused) {
    if (repeat) audio.currentTime = 0;
    audio
      .play()
      .catch((e) => {
        console.log(e);
      })
      .then(() => {
        console.log("music");
      });
  } else {
    if (repeat) audio.currentTime = 0;
  }
};
