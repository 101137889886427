import "src/assets/css/web.scss";
import { TiTick } from "react-icons/ti";
import { FaInfo } from "react-icons/fa";
import { Routes, Route } from "react-router-dom";

import Terms from "src/views/Common/Terms";
import Imprint from "src/views/Common/Imprint";
import Privacy from "src/views/Common/Privacy";
import UserPublicProfile from 'src/views/Common/UserPublicProfile'
import PaymentMessage from "src/views/Common/PaymentMessage";
import AppFooter from "src/components/AppFooter";
import SupportForm from "src/views/App/SupportForm";

function CommonRouter() {
  return (
    <>
      <Routes>
        <Route exact path="/terms" element={<Terms />} />
        <Route exact path="/imprint" element={<Imprint />} />
        <Route exact path="/privacy" element={<Privacy />} />
        <Route exact path="/customer" element={<SupportForm />} />
        <Route
          exact
          path="/success"
          element={
            <PaymentMessage
              icon={<TiTick />}
              message="You're payment is successful"
              desc="Your payment is successful and your package will be added shortly."
            />
          }
        />
        <Route
          exact
          path="/failed"
          element={
            <PaymentMessage
              icon={<FaInfo />}
              message="Payment Failed"
              desc="There was an issue processing your payment please try again."
            />
          }
        />
        <Route exact path="/p/:username" element={<UserPublicProfile />} />
      </Routes>
      <AppFooter />
    </>
  );
}
export default CommonRouter;
