import en from "./locales/en.json";
import de from "./locales/de.json";
import ch from "./locales/de.json";


const translations = {
  en: { translation: en },
  de: { translation: de },
  ch: { translation: ch },
};

export default translations;