import React, { lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PageSkeleton } from "src/components/common/Skeletons";
import { apolloClient } from "src/index";
import { PROFILE_PIC_UPDATE, PROFILE_SETUP, VERIFY_OTP } from "src/queries";
import {
  setUpdateProfileStep,
  setUserProfilePhoto,
} from "src/redux/user/userActions";
import { setMessageModal } from "src/redux/message/messageActions";
import { activeTheme } from "src/themes";
import { SETUP_COMPLETE_VALUE } from "src/utils/constants";

const Template = lazy(() =>
  import(`src/templates/${activeTheme}/views/ProfileSetup.jsx`)
);

const OtpVerification = lazy(() =>
  import(`src/templates/${activeTheme}/views/OtpVerification.jsx`)
);

function ProfileSetupParent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const setupStep = userData.userData.profileSetupStep;

  const uploadProfilePhoto = async (file) => {
    try {
      const response = await apolloClient.mutate({
        mutation: PROFILE_PIC_UPDATE,
        variables: {
          file,
        },
      });
      const profilePic = response.data.updateProfilePic;
      dispatch(setUserProfilePhoto(profilePic));
      return { status: true };
    } catch (error) {
      console.error("Mutation error:", error);
      return { status: false, error };
    }
  };

  const verifyOtp = async (data) => {
    try {
      const response = await apolloClient.mutate({
        mutation: VERIFY_OTP,
        variables: {
          otp: data.otp,
        },
      });

      dispatch(setUpdateProfileStep(3));
      navigate("/find-matches");
      dispatch(setMessageModal(true));
      return {
        status: true,
      };
    } catch (error) {
      console.error("Mutation error:", error);
      alert("Invalid Otp");
      return {
        status: true,
        error: error,
      };
    }
  };

  const setupProfile = async (data) => {
    try {
      const response = await apolloClient.mutate({
        mutation: PROFILE_SETUP,
        variables: {
          birthDate: new Date(data.birthDate),
          gender: data.gender,
          sexualPreference: data.sexOrientation,
          language: data.language,
          seeking: data.seeking,
        },
      });

      if (data.selectedFile) {
        await uploadProfilePhoto(data.selectedFile);
      } else {
        throw new Error("No photo selected");
      }

      dispatch(setUpdateProfileStep(2));
      dispatch(setMessageModal(true));
      setTimeout(() => {
        navigate("/find-matches");
      });
    } catch (error) {
      console.error("Mutation error:", error);
      throw error;
    }
  };

  if (setupStep === SETUP_COMPLETE_VALUE) {
    navigate("/find-matches");
  }

  return (
    <Suspense fallback={<PageSkeleton />}>
      {setupStep == 1 && <Template setupProfile={setupProfile} />}
      {setupStep == 2 && <OtpVerification verifyOtp={verifyOtp} />}
    </Suspense>
  );
}

export default ProfileSetupParent;
