/* eslint-disable */ 
import {SET_LOADING,SET_LOADING_FULL} from './loadingTypes'

const initialState = {
  value:false,
  fullPage:false
}

const loadingReducer = (state = initialState,action) => {
  // alert("Aad")
  switch(action.type) { 
    case SET_LOADING :
      return {
        ... state,
        value:action.value,
        fullPage:false
      }
      case SET_LOADING_FULL :
      return {
        ... state,
        value:action.value,
        fullPage:true
      }
    default : return state
  }
}

export default loadingReducer