import AppRouter from "src/views/App/Router";
import WebRouter from "src/views/Web/Router";
import CommonRouter from "src/views/Common/Router";

import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loader, Modal } from "src/components/SmallComponents";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import useDatingCommunityMeta from "src/hooks/useDatingCommunityMeta";

const WEB_ROUTES = ["/", "/register", "/login"];
const COMMON_ROUTES = ["/terms", "/imprint", "/privacy", "/success", "/failed", "/customer"];

function IndexWeb() {
  useDatingCommunityMeta();

  const location = useLocation();

  const loading = useSelector((state) => state.loading);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  let currentPath = location.pathname;
  let web = false;
  let common = false;
  let app = true;
  if (WEB_ROUTES.includes(currentPath)) {
    web = true;
    app = false;
  }
  
  if (COMMON_ROUTES.includes(currentPath) || currentPath.includes("/p/")) {
    common = true;
    app = false;
  }
  return (
    <>
      <GlobalLoader loading={loading} />
      <ToastContainer
        autoClose={1500}
        hideProgressBar={true}
        position={"top-center"}
      />
      {app && <AppRouter />}
      {web && <WebRouter />}
      {common && <CommonRouter />}
    </>
  );
}

const GlobalLoader = ({ loading }) => {
  if (!loading.value) return;
  if (loading.value && !loading.fullPage) return <Loader />;
  if (loading.value && loading.fullPage)
    return (
      <Modal>
        <div className="flex justify-center items-center h-screen">
          <div className="rounded-full h-20 w-20 bg-neutral-400 animate-ping" />
        </div>
      </Modal>
    );
};

export default IndexWeb;
