import { useEffect, useState } from "react";
import UserConversations from "src/components/Main/Sub/UserConversations";
import ChatBox from "src/components/Main/Sub/ChatBox";
import QuickChatHeader from "src/components/Main/Sub/QuickChatHeader";
import { setChatModal, setLoadingFull, setChat } from "src/redux/index";
import { Loader } from "src/components/SmallComponents";
import { playSound } from "src/components/common/AudioMedia";

import EventEmitter from "src/event";

function QuickChatTab(props) {
  const {
    readMessages,
    conversationExists,
    unreadMessages,
    setUnreadMessages,
    selectedConversation,
    setSelectedConversation,
    userData,
    chat,
    dispatch,
    getUnreadMessages,
    getConversationMessages,
    loading,
  } = props;

  const [quickChatOpen, setQuickChatOpen] = useState(false);

  useEffect(() => {
    if (!selectedConversation) {
      EventEmitter.addListener("MESSAGE_LISTEN", ({ data }) => {
        handleMessageListen(data?.data?.messageAdded?.mes.sender.username);
      });
      EventEmitter.addListener("NEW_MESSAGE_SENT", ({ data }) => {
        setQuickChatOpen(true);
      });
    }
    return () => {
      EventEmitter.removeAllListeners();
    };
  }, [selectedConversation]);

  useEffect(() => {
    getUnreadMessages();
  }, []);

  useEffect(() => {
    (async () => {
      if (chat.chatType == "quick_chat") {
        dispatch(setLoadingFull(true));
        let convoExists = await conversationExists(String(chat.user.id));
        dispatch(setLoadingFull(false));
        if (!convoExists.status) {
          return;
        }
        if (convoExists.data.dmTargetInfo.convExsists) {
          getConversationMessages(
            convoExists.data.dmTargetInfo.conversation.id
          );
          setQuickChatOpen(true);
        } else {
          setSelectedConversation(() => null);
          setQuickChatOpen(false);
          dispatch(setChatModal(true));
          // console.log("setting chat modal");
        }
      }
    })();
  }, [chat.user]);

  const handleMessageListen = (fromUsername) => {
    if (!selectedConversation) {
      if (fromUsername != userData.userData.username) {
        playSound("new_message");
        getUnreadMessages();
      }
    }
  };

  const conversationSelectEvent = async (convo) => {
    getConversationMessages(convo.id);
  };

  const unselectConversation = () => {
    setSelectedConversation(null);
    dispatch(setChat(null, null));
  };

  return (
    <>
      <div
        style={{ maxHeight: "700px", overflowY: "scroll" }}
        className="fixed bottom-0 right-0 w-full md:w-1/3 lg:w-1/4 z-50"
      >
        {loading && <Loader />}
        {selectedConversation?.convo && (
          <ChatBox
            selectedConversation={selectedConversation}
            userData={userData}
            type={"quick_chat"}
            unselectConversation={unselectConversation}
            setSelectedConversation={setSelectedConversation}
            readMessages={readMessages}
          />
        )}
        {!selectedConversation && (
          <div>
            <QuickChatHeader
              quickChatOpen={quickChatOpen}
              setQuickChatOpen={setQuickChatOpen}
              unreadMessageLength={unreadMessages.length}
            />
            {quickChatOpen && (
              <UserConversations
                type={"quick_chat"}
                conversationSelectEvent={conversationSelectEvent}
                unreadMessages={unreadMessages}
                getUnreadMessages={getUnreadMessages}
              />
            )}
          </div>
        )}
        {/* <div className={`${selectedConversation ? "hidden" : ""}`}>
          <QuickChatHeader
            quickChatOpen={quickChatOpen}
            setQuickChatOpen={setQuickChatOpen}
            unreadMessageLength={unreadMessages.length}
          />
          {quickChatOpen && <UserConversations
            type={"quick_chat"}
            conversationSelectEvent={conversationSelectEvent}
            unreadMessages={unreadMessages}
          />}
        </div> */}
      </div>
    </>
  );
}
export default QuickChatTab;
