import { activeTheme } from "src/themes";
import { lazy, Suspense, useEffect, useState } from "react";
import { LIKED } from "src/queries";
import { apolloClient } from "src/index";
import { LikedLoading } from "src/components/common/Skeletons";


const Template = lazy(() =>
  import(`src/templates/${activeTheme}/views/Liked.jsx`)
);

function Liked() {

  const [likedProfiles,setLikedProfiles] = useState([])
  const [loading,setLoading] = useState(false)

  const getUserLiked = async () => {
    try{ 
      setLoading(true)
      const { data, errors } = await apolloClient.query({
        query: LIKED,
        fetchPolicy:"no-cache"
      });
      setLoading(false)
      setLikedProfiles(data.liked)
    }catch(e) { 
      setLoading(false)
      alert(e)
    }
  }

  useEffect(()=>{
    getUserLiked()
  },[])


  return (
    <Suspense fallback={<LikedLoading />}>
      <Template likedProfiles={likedProfiles} loading={loading} />
    </Suspense>
  );
}
export default Liked;
