import { gql } from "@apollo/client";

export const USER_EXISTS = gql`
  query userExists($username: String!, $email: String!) {
    userExists(username: $username, email: $email)
  }
`;

export const GET_MEDIA_BY_CATEGORY = gql`
  query getMediaByCategory($category: String!) {
    getMediaByCategory(category: $category) {
      id
      url
      title
      description
      preview_url
      thumbnail
      potrait
    }
  }
`;

export const SEND_POKE = gql`
  mutation sendPoke($input: String!) {
    sendPoke(input: $input) {
      error
      poke {
        id
        createdAt
        updatedAt
      }
    }
  }
`;

export const NEW_REGISTER = gql`
  mutation newRegister(
    $username: String!
    $password: String!
    $email: String!
    $captcha: String!
  ) {
    newRegister(
      username: $username
      password: $password
      email: $email
      captcha: $captcha
    ) {
      user {
        id
        usrType
        username
        profilePic
        profileSetupStep
      }
      token
      credit {
        balance
      }
    }
  }
`;

export const GET_FLIRTONS = gql`
  query {
    all_flirtons {
      id
      packageName
      packageDesc
      cover
      packageId
      creditValue
      packageType
      price
      createdAt
    }
  }
`;

export const HOME_PAGE_FAKES_LIST = gql`
  query {
    getRandomUsersHome {
      id
      username
      profilePic
      photos {
        image
      }
      base_profile {
        firstName
      }
    }
  }
`;

export const USER_LOGIN = gql`
  mutation login(
    $usernameOrEmail: String!
    $password: String!
    $addr: String!
  ) {
    login(usernameOrEmail: $usernameOrEmail, password: $password, addr: $addr) {
      errors {
        field
        message
      }
      token
      user {
        used_hpass
        authSource
        profilePic
        username
        email
        profileSetupStep
        seeking
        profileComplete
        photos {
          id
          image
          unsafe
        }
        looks {
          gender
        }
        base_profile {
          id
          firstName
          lastName
        }
        user_basic {
          location
        }
      }
      credit {
        id
        balance
        flirtons
        flirtonsAvailable
      }
    }
  }
`;

export const VERIFY_OTP = gql`
  mutation verifyOtp($otp: String!) {
    verifyOtp(otp: $otp) {
      user {
        profilePic
        username
        email
        age
        usrType
        profileSetupStep
        profileComplete
        photos {
          id
          image
          unsafe
        }
        base_profile {
          id
          firstName
          lastName
        }
        user_basic {
          location
        }
      }
      credit {
        id
        balance
        flirtons
        flirtonsAvailable
      }
    }
  }
`;

export const REGISTER_FIREBASE = gql`
  mutation registerWithFirebase($token: String!) {
    registerWithFirebase(token: $token) {
      token
      user {
        authSource
        profilePic
        username
        email
        profileSetupStep
        profileComplete
        photos {
          id
          image
          unsafe
        }
        base_profile {
          id
          firstName
          lastName
        }
        user_basic {
          location
        }
      }
      credit {
        id
        balance
        flirtons
        flirtonsAvailable
      }
    }
  }
`;

export const FIND_MATCHES_LIST = gql`
  query find_matches($input: PaginationInput!, $addr: String!) {
    find_matches(input: $input, addr: $addr) {
      data {
        id
        # photos {
        #   id
        #   image
        # }
        birth_date
        age
        username
        photos {
          id
          image
          unsafe
        }
        # email
        # createdAt
        profilePic
        # tempLocationDetail
        # userLocation
        # usrType
        # base_profile {
        #   id
        #   firstName
        #   lastName
        # }
        looks {
          gender
        }
      }
    }
  }
`;
export const BUY_NUDE = gql`
  mutation buyNude($id: Float!) {
    buyNude(id: $id) {
      url
      balance
    }
  }
`;
export const DM_TARGET_INFO = gql`
  query dmTargetInfo($dtarget: String!) {
    dmTargetInfo(dtarget: $dtarget) {
      target {
        id
        username
        usrType
        email
        base_profile {
          id
          firstName
          lastName
        }
        profilePic
      }
      conversation {
        id
      }
      convExsists
    }
  }
`;

export const HOT_ORNOT_SET = gql`
  query hotOrNotUsers($input: PaginatedUsersInput!) {
    hotOrNotUsers(input: $input) {
      id
      username
      usrType
      base_profile {
        id
        firstName
        lastName
      }
      profilePic
    }
  }
`;
export const LIKE_PROFILE = gql`
  mutation like_profile($user: String!) {
    like_profile(user: $user)
  }
`;
export const DISLIKE_PROFILE = gql`
  mutation dislike_profile($user: String!) {
    dislike_profile(user: $user)
  }
`;
export const ALL_CONVERSATIONS = gql`
  query {
    allConversations {
      id
      m_one {
        id
        username
        usrType
        profilePic
        base_profile {
          id
          firstName
          lastName
        }
      }
      m_two {
        id
        username
        usrType
        base_profile {
          id
          firstName
          lastName
        }
      }
      lastMessage {
        id
        body
        createdAt
      }
    }
  }
`;

export const GET_MESSAGES = gql`
  query allMessages($conv: Float!) {
    allMessages(conv: $conv) {
      messages {
        id
        status
        body
        read
        message_type
        translations {
          en
        }
        paid
        sender {
          id
          username
          email
          profilePic
          base_profile {
            id
            firstName
            lastName
          }
        }
        attachment_type
        attachment_url
      }
      convo {
        id
        m_one {
          id
          username
          profilePic
          base_profile {
            id
            firstName
            lastName
          }
        }
        m_two {
          id
          username
          base_profile {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const GET_UNREAD_MESSAGES = gql`
  query {
    allUnreadMessages {
      id
      body
      sender {
        id
        username
        profilePic
      }
    }
  }
`;

export const READ_MESSAGES = gql`
  mutation readMessages($messages: String!) {
    readMessages(messages: $messages)
  }
`;

export const SEND_MESSAGE = gql`
  mutation sendMessage($input: ChatMessageInput!, $file: Upload) {
    sendMessage(input: $input, file: $file) {
      id
      status
      body
      paid
      message_type
      attachment_url
      sender {
        id
        username
        profilePic
        base_profile {
          id
          firstName
          lastName
        }
        createdAt
      }
      flirts
    }
  }
`;

export const USER_PROFILE = gql`
  query userProfile($username: String!) {
    userProfile(username: $username) {
      likes
      visits
      user {
        usrType
        id
        username
        email
        age
        profilePic
        profileSetupStep
        profileComplete
        package_activity {
          id
          active
          start_stamp
          package {
            id
            timeValue
            packageStart
            packageEnd
            cover
            packageName
          }
        }
        photos {
          id
          image
          unsafe
        }
        mediaGallery {
          media {
            id
            url
            cloudUrl
            preview_url
            thumbnail
            mediaCloudName
            potrait
            cost
            is_paid
            format
            is_purchased
          }
        }
        base_profile {
          id
          firstName
          lastName
        }
        createdAt
        looks {
          id
          ethnicity
          body_type
          gender
          height
          hair_color
        }
        user_basic {
          id
          bio
          relationship
          workstatus
          education_level
        }
        lifeStyle {
          id
          live_with
          car
          religion
          smoke
          drinking
          travel
        }
      }
    }
  }
`;

export const USER_PURCHASED_MEDIA = gql`
  query getUserPurchasedMedia {
    getUserPurchasedMedia {
      id
    }
  }
`;

export const PURCHASE_MEDIA = gql`
  mutation purchaseMedia($media: Float!) {
    purchaseMedia(media: $media) {
      balance
      flirtons
    }
  }
`;

export const GET_CLOUD_URL = gql`
  query getCloudUrl($filename: String!) {
    getCloudUrl(filename: $filename)
  }
`;

export const USER_SETTINGS_DATA = gql`
  query {
    userSettingsData {
      user {
        id
        email
        username
        profilePic
        photos {
          image
        }
        base_profile {
          id
          firstName
          lastName
          phone
        }
        user_basic {
          id
          bio
          relationship
          workstatus
          education_level
          location
        }
        looks {
          id
          ethnicity
          body_type
          height
          hair_color
        }
        personality {
          id
          character
          children
          friends
          pets
        }
        lifeStyle {
          id
          live_with
          car
          religion
          smoke
          drinking
          travel
        }
        favourite {
          id
          music_genre
          dish
          song
          hobby
          city
          sport
          book
          movie
          color
          tv_show
        }
        socialLink {
          id
          facebook
          instagram
          twitter
          linkedIn
        }
      }
    }
  }
`;
export const UPDATE_BASE_PROFILE = gql`
  mutation updateBaseProfile($input: BasicUserDataUpdateInput!) {
    updateBaseProfile(input: $input) {
      user {
        base_profile {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const UPDATE_PROFILE_DETAILS = gql`
  mutation updateUserProfileDetails($input: ProfileSetupUpdate!) {
    updateUserProfileDetails(input: $input) {
      user {
        base_profile {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const PURCHASE_USER_PHOTO = gql`
  mutation purchaseUserPhoto($media: Float!) {
    purchaseUserPhoto(media: $media) {
      credit {
        balance
        flirtons
        flirtonsAvailable
      }
      photo {
        id
        image
      }
    }
  }
`;

export const PROFILE_DETAIL_SETUP_TYPES = gql`
  query {
    profileDetailSetupTypes {
      workStatus
      relationShipStatus
      educationStatus
    }
  }
`;

export const LOOKS_PERSONALITY_TYPES = gql`
  query {
    looksAndPersonalitySetupTypes {
      ethnicity
      bodyType
      hairColor
      character
      children
      friends
      pets
    }
  }
`;
export const UPDATE_PROFILE_PIC = gql`
  mutation updateProfilePic($file: Upload!) {
    updateProfilePic(file: $file)
  }
`;

export const MULTIPLE_UPLOAD = gql`
  mutation addProfilePics($files: [Upload!]!) {
    addProfilePics(files: $files) {
      id
      image
      unsafe
    }
  }
`;
export const UPDATE_USER_LOOKS = gql`
  mutation updateUserLooksDetail($input: LooksAndPersonalityInputType!) {
    updateUserLooksDetail(input: $input) {
      user {
        base_profile {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const UPDATE_USER_LISFESTYLE = gql`
  mutation updateUserLifeStyleDetail($input: LifeStyleAndFavouritesInputType!) {
    updateUserLifeStyleDetail(input: $input) {
      user {
        base_profile {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const LIFE_STYLE_FAVOURITES_LS = gql`
  {
    lifeStyleAndFavouritesSetupTypes {
      livingWith
      car
      religion
      smoke
      drink
      travel
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changeUserPassword($input: PasswordChangeInput!) {
    changeUserPassword(input: $input)
  }
`;

export const UPDATE_SOCIAL_LINK = gql`
  mutation updateSocialLinks($input: SocialLinkInput!) {
    updateSocialLinks(input: $input) {
      user {
        base_profile {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
export const VISITS = gql`
  query {
    visits {
      id
      visitor {
        id
        username
        username
        email
        profilePic
        profileSetupStep
        profileComplete
        base_profile {
          id
          firstName
          lastName
        }
      }
      #   visitee{
      #     id
      #     username
      #   }
    }
  }
`;

export const LIKES = gql`
  query {
    likes {
      id
      liked_by {
        id
        username
        username
        email
        profilePic
        profileSetupStep
        profileComplete
        base_profile {
          id
          firstName
          lastName
        }
      }
      liked_status
      createdAt
    }
  }
`;
export const LIKED = gql`
  query {
    liked {
      id
      user {
        id
        username
        username
        email
        profilePic
        profileSetupStep
        profileComplete
        base_profile {
          id
          firstName
          lastName
        }
      }
      liked_status
      createdAt
    }
  }
`;
export const DISLIKED = gql`
  query {
    dislikes {
      id
      user {
        id
        username
        username
        email
        profilePic
        profileSetupStep
        profileComplete
        base_profile {
          id
          firstName
          lastName
        }
      }
      disliked_status
      createdAt
    }
  }
`;
export const DISLIKES = gql`
  query {
    dislikes {
      id
      user {
        id
        username
        username
        email
        profilePic
        profileSetupStep
        profileComplete
        base_profile {
          id
          firstName
          lastName
        }
      }
      createdAt
    }
  }
`;

export const NOTIFICATIONS = gql`
  query {
    notifications {
      id
      read
      message
      sparoute
      link
      ref_user {
        id
        username
        email
        profilePic
        base_profile {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_PACKAGES = gql`
  query {
    all_packages {
      packages {
        id
        packageName
        packageDesc
        cover
        packageId
        creditValue
        packageType
        price
        euroPrice
        poundPrice
        createdAt
        is_available
      }
      active_packages {
        id
        is_available
      }
    }
  }
`;

export const START_PASS = gql`
  mutation {
    startHourlyPackage {
      package_activity {
        id
        active
        start_stamp
        package {
          id
          timeValue
          packageStart
          packageEnd
          cover
          packageName
          packageEndStamp
          packageStartStamp
        }
      }
      activePackage {
        packageType
      }
      activePackageExpireTimestamp
      has_hpass
      used_hpass
      start_timestamp
      h_pass_start
      finished_hpass
      publicIp
      tempLocationDetail
      profilePic
      coverVideo
      username
      email
      authSource
      profileSetupStep
      profileComplete
      photos {
        id
        image
        unsafe
      }
      base_profile {
        id
        firstName
        lastName
        phone
      }
      user_basic {
        id
        bio
        relationship
        education_level
        workstatus
        location
      }
      looks {
        id
        ethnicity
        body_type
        height
        height_units
        hair_color
      }
      personality {
        id
        character
        children
        friends
        pets
      }
      lifeStyle {
        id
        live_with
        car
        religion
        smoke
        drinking
        travel
      }
      favourite {
        id
        music_genre
        dish
        song
        hobby
        city
        sport
        book
        movie
        color
        tv_show
      }
      socialLink {
        id
        facebook
        instagram
        twitter
        linkedIn
      }
      createdAt
    }
  }
`;

export const CHECKOUT_STRIPE = gql`
  mutation createStripeCheckout($input: CheckoutInput!) {
    createStripeCheckout(input: $input)
  }
`;

export const PUBLIC_PROFILE = gql`
  query publicProfile($username: String!) {
    publicProfile(username: $username) {
      likes
      visits
      user {
        id
        username
        email
        age
        profilePic
        profileSetupStep
        profileComplete
        package_activity {
          id
          active
          start_stamp
          package {
            id
            timeValue
            packageStart
            packageEnd
            cover
            packageName
          }
        }
        photos {
          id
          image
          unsafe
        }
        mediaGallery {
          media {
            id
            url
            cloudUrl
            preview_url
            thumbnail
            mediaCloudName
            potrait
            cost
            is_paid
            format
            is_purchased
          }
        }
        base_profile {
          id
          firstName
          lastName
        }
        createdAt
        looks {
          id
          ethnicity
          body_type
          gender
          height
          hair_color
        }
        user_basic {
          id
          bio
          relationship
          workstatus
          education_level
        }
        lifeStyle {
          id
          live_with
          car
          religion
          smoke
          drinking
          travel
        }
      }
    }
  }
`;

export const GET_ALL_GIFTS = gql`
  query {
    gifts {
      id
      asset
      cost
    }
  }
`;

export const CREATE_COINBASE_CHARGE = gql`
  mutation CoinbaseCharge($package: String!) {
    CoinbaseCharge(package: $package) {
      charge
    }
  }
`;

export const GET_ALL_TRANSACTIONS = gql`
  query {
    transactions {
      paymentSource
      amount
      createdAt
      package {
        packageName
        packageDesc
        creditValue
        subscriptionDuration
        packageStart
        packageEnd
        is_available
        packageType
      }
    }
  }
`;

///////////////////////////////////////////////////////////////////////// SUBSCRIPTIONS ////////////////////////////////////////////////////////////
export const MESSAGE_LISTEN = gql`
  subscription messageAdded {
    messageAdded {
      mes {
        id

        body
        attachment_url
        message_type

        sender {
          username

          createdAt
        }
      }
    }
  }
`;

export const NOTIFICATION_ADDED = gql`
  subscription {
    notificationAdded {
      id
      read
      message
      ref_user {
        id
        username
        email
        base_profile {
          id
          firstName
          lastName
        }
        profilePic
      }
      user {
        id
        username
        base_profile {
          id
          firstName
          lastName
        }
        profilePic
      }
    }
  }
`;

export const PROFILE_SETUP = gql`
  mutation userProfileSetup(
    $birthDate: String!
    $gender: String!
    $sexualPreference: String!
    $language: String!
    $seeking: String!
  ) {
    userProfileSetup(
      birthDate: $birthDate
      gender: $gender
      sexualPreference: $sexualPreference
      language: $language
      seeking: $seeking
    ) {
      profileSetupStep
      id
      looks {
        gender
        preference
      }
      birth_date
    }
  }
`;

export const UPDATE_USER_LOCATION = gql`
  mutation updateUserLocation($input: updateLocationInput!) {
    updateUserLocation(input: $input)
  }
`;

export const AVAIL_WHEEL_FORTUNE = gql`
  mutation availWheelBonus(
    $coins: String!
    $type: String!
    $discountCode: String!
    $discount: String!
  ) {
    availWheelBonus(
      coins: $coins
      type: $type
      discountCode: $discountCode
      discount: $discount
    )
  }
`;

export const GET_USER_PLACES = gql`
  query getUserPlaces($lat: String!, $lng: String!) {
    getUserPlaces(lat: $lat, lng: $lng)
  }
`;

export const DELETE_MY_ACCOUNT = gql`
  mutation {
    deleteMyAccount
  }
`;
export const GET_USER_DISCOUNT = gql`
  query {
    getUserAvailableDiscounts {
      id
      type
      coins
      discountCode
      discountPercent
      expireTimestamp
      createdAt
    }
  }
`;

export const PROFILE_PIC_UPDATE = gql`
  mutation updateProfilePic($file: Upload!) {
    updateProfilePic(file: $file)
  }
`;
